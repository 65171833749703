<template>
    <div>
        <div id="qs-header">
            <qs-header cur-menu="app"></qs-header>
        </div>
        <div class="max-w-7xl px-0 lg:px-8 flex flex-col mx-auto">
            <div id="app-content">
                <app-content></app-content>
            </div>
        </div>
    </div>
</template>


<script>
import QsHeader from '../qs-header.vue';
import AppContent from '../app-content.vue';

export default {
    components: {
        "qs-header": QsHeader,
        "app-content": AppContent,
    }
}
</script>