<template>
  <div class="relative emoji-picker" ref="dropdown">
    <button @click="toggleEmojiPicker" type="button" class="emoji-btn text-xl rounded hover:bg-gray-200 p-2">😊</button>
    <div v-show="isEmojiPickerVisible"
      class="emoji-picker-content absolute -right-1/2 bottom-12 bg-white rounded-lg border border-gray-300 shadow px-2 py-1 flex space-x-2">
      <button @click="addEmoji('😀')" class="emoji text-xl">😀</button>
      <button @click="addEmoji('😂')" class="emoji text-xl">😂</button>
      <button @click="addEmoji('😍')" class="emoji text-xl">😍</button>
      <button @click="addEmoji('😭')" class="emoji text-xl">😭</button>
      <button @click="addEmoji('😔')" class="emoji text-xl">😔</button>
      <button @click="addEmoji('👍')" class="emoji text-xl">👍</button>
      <button @click="addEmoji('👎')" class="emoji text-xl">👎</button>
      <button @click="addEmoji('😊')" class="emoji text-xl">😊</button>
      <button @click="addEmoji('😘')" class="emoji text-xl">😘</button>
      <button @click="addEmoji('😇')" class="emoji text-xl">😇</button>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      isEmojiPickerVisible: false,
    };
  },
  methods: {
    toggleEmojiPicker() {
      this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
    },
    addEmoji(emoji) {
      this.$emit('emoji-added', emoji);
      this.isEmojiPickerVisible = false;
    },
    handleClickOutside(event) {
      //console.log(this.$refs.dropdown, event.target);
      // 如果点击事件的目标不是 dropdown 元素或其子元素，则关闭 dropdown
      if (!this.$refs.dropdown.contains(event.target)) {
        this.isEmojiPickerVisible = false;
      }
    }
  },
  mounted() {
    // 在 document 上添加点击事件监听，用于关闭 dropdown
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() { // 如果使用 Vue 2，应该是 beforeDestroy
    // 移除事件监听，清理资源
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>