<template>
    <div>
        <div id="qs-header">
            <qs-header cur-menu="support"></qs-header>
        </div>
        <div class="max-w-7xl px-4 lg:px-8 flex flex-col mx-auto">
            <div id="search-type" class="lg:pb-6 pt-10 px-4">
                <search-type code="TechCategory"></search-type>
            </div>
            <div id="support-elm">
                <support-elm></support-elm>
            </div>
        </div>
    </div>
</template>

<script>
import QsHeader from '../qs-header.vue';
import SupportElm from '../support-elm.vue';
import SearchType from '../search-type.vue';

export default {
    components: {
        "qs-header": QsHeader,
        "support-elm": SupportElm,
        "search-type": SearchType,
    }
}
</script>