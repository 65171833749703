import Vue from 'vue'
import { API_URL, USER_INFO, IMAGE_URL, FILE_URL, SHOW_LOGIN_DIALOG } from "./const.js";

const RestfulToken = "RestfulAuthToken";

// Vue.config.errorHandler = function (err, vm, info) {
//     // err 是错误对象
//     // vm 是出错的Vue实例
//     // info 是一个包含Vue特定的错误信息的字符串
//     console.error('捕获到错误：', err);
//     console.info('错误所在的组件实例：', vm);
//     console.info('错误信息：', info);
// };

export async function callApi(id, json, log) {
    console.log("callApi", id, json, log);
    const res = await postJson(API_URL + "/api/" + id + "?log=" + (log || ""), json || {})
    if (res.status === -100) { // token 过期或无权限     
        await userLogout();
        localStorage.removeItem(USER_INFO);
        showLoginDialog()
    }

    return res;
}

export function callProxyApi(path, json) {
    return postJson(API_URL + "/proxy" + path, json || {})
    //return postJson("http://localhost:8601" + path, json || {})
}

export function getOptions(path) {
    return getJson(API_URL + "/options" + path)
}

export function sendFile(file, querys, progress) {
    // 参数 querys 可以为 null
    return postFile(API_URL + "/upload?" + querys, file, progress)
}

export function userLogin(json) {
    console.log("userLogin", json)
    return postJson(API_URL + "/login", json)
}

export function getLocalUserInfo() {
    const data = localStorage.getItem(USER_INFO)
    if (!data) {
        return null;
    }
    try {
        return JSON.parse(data)
    } catch (e) {
        console.error("parse user info failed: ", e, data)
    }
}

export function userLogout() {
    return postJson(API_URL + "/logout", {})
}

function getJson(url) {
    const userInfo = getLocalUserInfo();
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true; // 这会包含 cookies
        xhr.open('GET', url, true);
        if (userInfo) {
            xhr.setRequestHeader(RestfulToken, userInfo.restful_hash)
        }
        xhr.send();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                    let res = JSON.parse(xhr.responseText);
                    resolve(res);
                } else {
                    reject(xhr.status);
                }
            }
        }
    })
}

export function postJson(url, json) {
    const userInfo = getLocalUserInfo();
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true; // 这会包含 cookies
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        if (userInfo) {
            xhr.setRequestHeader(RestfulToken, userInfo.restful_hash)
        }
        xhr.send(JSON.stringify(json));
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                    let res = JSON.parse(xhr.responseText);
                    resolve(res);
                } else {
                    reject(xhr.status);
                    console.error("callApi error", xhr.responseText);
                }
            }
        }
    })
}

export function postFile(url, file, progress) {

    const userInfo = getLocalUserInfo();
    var axios = require('axios');

    const myData = new FormData();
    myData.append('file', file);

    // 结果转为 json
    return axios.request({
        method: "post",
        url: url,
        data: myData,
        headers: {
            'Content-Type': 'multipart/form-data',
            RestfulToken: userInfo.restful_hash
        },
        withCredentials: true,
        onUploadProgress: (p) => {
            console.log(p);
            progress(p.loaded / p.total * 100);
        }
    }).then(res => {
        return res.data;
    })
}

// 使用form发送文件
export function postFile2(url, file, progress) {

    const userInfo = getLocalUserInfo();
    return new Promise((resolve, reject) => {

        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true; // 这会包含 cookies
        xhr.open('POST', url, true);
        if (userInfo) {
            xhr.setRequestHeader(RestfulToken, userInfo.restful_hash)
        }
        const form = new FormData();
        form.append('file', file);
        // 进度
        if (progress) {

            xhr.upload.addEventListener('loadstart', function (e) {
                progress(0);
            });

            xhr.upload.addEventListener('progress', function (e) {
                if (e.lengthComputable) {
                    progress(e.loaded / e.total * 100);
                }
            });

            xhr.upload.onloadend = function (e) {
                progress(100);
            };
        }

        xhr.send(form);

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                    let res = JSON.parse(xhr.responseText);
                    resolve(res);
                } else {
                    reject(xhr.status);
                    console.error("callApi error", xhr.responseText);
                }
            }
        }
    })
}

// tinymce使用发送文件
export function tinymceUploadFile(blobInfo, success, failure, progress) {
    var xhr, formData;
    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', API_URL + '/upload', true);

    const userInfo = getLocalUserInfo();
    if (userInfo) {
        xhr.setRequestHeader(RestfulToken, userInfo.restful_hash)
    }

    xhr.upload.addEventListener("loadstart", function (e) {
        progress(0);
    }, false);

    xhr.upload.addEventListener("progress", function (e) {
        if (e.lengthComputable) {
            progress(e.loaded / e.total * 100);
        }
    });

    xhr.onload = function () {
        var json;
        if (xhr.status == 403) {
            failure('HTTP Error: ' + xhr.status, { remove: true });
            return;
        }
        if (xhr.status < 200 || xhr.status >= 300) {
            failure('HTTP Error: ' + xhr.status);
            return;
        }
        json = JSON.parse(xhr.responseText);
        if (!json || typeof json.location != 'string') {
            failure('Invalid JSON: ' + xhr.responseText);
            return;
        }
        success(json.data.value);
    };

    xhr.onerror = function () {
        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    }

    formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
}

export function ImageUrl(path) {
    if (!path) {
        return "";
    }
    // 检查 path 是否以 http:// 或 https:// 开头
    if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
    }
    return IMAGE_URL + path;
}

export function FileUrl(path) {
    if (!path) {
        return "";
    }

    // 检查 path 是否以 http:// 或 https:// 开头
    if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
    }
    return IMAGE_URL + path;
}

export function getQueryParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export function setQueryParams(key, value) {
    // 检查是否有一个有效的键和值
    if (!key || value === undefined || value === null) {
        return;
    }

    // 创建一个新的URL对象，使用当前窗口的URL
    let url = new URL(window.location.href);

    // 获取当前URL的查询参数
    let params = new URLSearchParams(url.search);

    // 设置新的查询参数
    params.set(key, value);

    // 更新URL的查询部分
    url.search = params.toString();

    return url;
    // 更新浏览器的地址栏（不重新加载页面）
    //window.history.pushState({}, '', url);
}

export function formatRelativeTime(timeValue) {
    const currentTime = new Date();
    const targetTime = new Date(timeValue);

    const timeDifference = currentTime - targetTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);

    const browserLanguage = navigator.language;

    if (months > 0) {
        const formattedDate = targetTime.toLocaleDateString(browserLanguage, { month: 'numeric', day: 'numeric' });
        return formattedDate;
    } else if (days > 0) {
        if (browserLanguage.startsWith('zh')) {
            return `${days}天前`;
        } else {
            return `${days} days ago`;
        }
    } else if (hours > 0) {
        if (browserLanguage.startsWith('zh')) {
            return `${hours}小时前`;
        } else {
            return `${hours} hours ago`;
        }
    } else if (minutes > 0) {
        if (browserLanguage.startsWith('zh')) {
            return `${minutes}分钟前`;
        } else {
            return `${minutes} minutes ago`;
        }
    } else {
        if (browserLanguage.startsWith('zh')) {
            return '刚刚';
        } else {
            return 'just now';
        }
    }
}


// 计算剩余天数 
export function getRemainDays(timeValue) {
    const currentTime = new Date();
    const targetTime = new Date(timeValue);
    const timeDifference = targetTime - currentTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    return days;
}

export function updateRichTextPaths(richText) {
    if (!richText) return richText;
    // 替换图片路径
    const updatedRichTextWithImage = richText.replace(/<img[^>]+src="(?!http:\/\/|https:\/\/|\/\/)([^">]+)"/g, `<img src="${IMAGE_URL}/$1"`);

    // 替换视频路径
    const updatedRichTextWithVideo = updatedRichTextWithImage.replace(/<video[^>]+src="(?!http:\/\/|https:\/\/|\/\/)([^">]+)"/g, `<video src="${FILE_URL}/$1"`);

    // 替换音频路径
    const updatedRichTextWithAudio = updatedRichTextWithVideo.replace(/<audio[^>]+src="(?!http:\/\/|https:\/\/|\/\/)([^">]+)"/g, `<audio src="${FILE_URL}/$1"`);

    // 添加文件下载链接
    const updatedRichTextWithDownloadLink = updatedRichTextWithAudio.replace(/<a[^>]+href="(?!http:\/\/|https:\/\/|\/\/)([^">]+)"/g, `<a href="${FILE_URL}/$1"`);

    return updatedRichTextWithDownloadLink;
}

export function shareContent(title, text, url) {
    title = '分享标题';
    text = '分享内容';
    url = 'https://example.com';

    if (navigator.share) {
        navigator.share({
            title: title,
            text: text,
            url: url
        })
            .then(() => console.log('分享成功'))
            .catch(error => console.log('分享失败:', error));
    } else {
        console.log('该浏览器不支持 Web Share API');
    }
}

// 将用户的动作行为转为对象
export function userActionInfo(data) {
    let obj = {};
    for (let a of data) {
        obj[a["action_type"]] = a["count"]
    }

    return obj;
}

export function userActionCountAddOne(user_action, key) {
    user_action[key] = user_action[key] ? user_action[key] + 1 : 1
}

export function userActionCountSubOne(user_action, key) {
    user_action[key] = user_action[key] ? user_action[key] - 1 : 0
}

// 显示登录窗口
export function showLoginDialog() {
    console.log("showLoginDialog")
    EventBus.$emit(SHOW_LOGIN_DIALOG);
}

export async function copyCurrentLink() {
    const user_info = getLocalUserInfo();
    const user_id = user_info ? user_info.id : '';
    console.log("copyCurrentLink", user_id)
    try {
        let link = window.location.href
        if (link.includes("?")) {
            link = link + "&rmd_id=" + user_id
        } else {
            link = link + "?rmd_id=" + user_id
        }
        await navigator.clipboard.writeText(link);

        // 插入一个元素，以中间位置显示提示
        let span = document.createElement('span');
        span.className = "copy-link-tips text-green-500 text-xl font-bold ";
        span.innerText = "复制推荐链接成功";
        span.style.position = "fixed";
        span.style.left = "50%";
        span.style.top = "70%";

        span.style.transform = "translate(-50%, -50%)";

        document.body.appendChild(span);
        setTimeout(function () {
            document.body.removeChild(span);
        }, 2000);

    } catch (err) {
        console.error('复制推荐链接失败', err);
    }
}

export function setSiteInfo(title, description) {
    // 设置网站标题和描述
    if (title) {
        document.title = title;
    }

    if (description) {
        // 查看是否有 description 元素，如果没有就创建一个
        if (!document.querySelector('meta[name="description"]')) {
            let meta = document.createElement('meta');
            meta.name = 'description';
            meta.content = description;
            document.head.appendChild(meta);
        } else {
            document.querySelector('meta[name="description"]').setAttribute('content', description);
        }
    }
}

export function saveRecommandId() {
    let rmd_id = getQueryParam("rmd_id")
    if (rmd_id) {
        localStorage.setItem("rmd_id", rmd_id)
    }
}

export function getRecommandId() {
    return localStorage.getItem("rmd_id")
}

// 检测是否为微信中
export function isWechat() {
    return /MicroMessenger/i.test(navigator.userAgent);
}

// 检测是否为 android
export function isAndroid() {
    return /android/i.test(navigator.userAgent);
}

// 检测是否为 ios
export function isIOS() {
    return /iphone|ipad|ipod/i.test(navigator.userAgent);
}

// 获取用户 openid; 如果已经有，则直接返回，否则需要授权
export function getUserOpenid() {
    if (!isWechat()) return;

    // 取得 cookie 中的 openid
    const openid = getCookie("openid");
    // alert("cookie");
    //alert(document.cookie)
    if (openid) {
        // 清除 cookie
        // document.cookie = "openid=;expires=" + new Date(0).toUTCString();
        return openid;
    }
    // 5秒内请求一次 openid
    const request_openid_time = localStorage.getItem("request_openid_time");
    if (request_openid_time && Date.now() - request_openid_time < 5 * 1000) {
        return;
    }

    localStorage.setItem("request_openid_time", Date.now());
    const url = "/a_xia_yun/proxy/wx_openid/wx/get_openid/a_xia_yun?redirect_url=" + encodeURIComponent(location.href)
    // alert(url)
    // alert(document.cookie)
    // 如果没有 openid，则跳转到微信授权
    location.href = url
}


export function getCookie(name) {
    // 将document.cookie字符串分割成数组
    var cookies = document.cookie.split('; ');
    // 遍历数组，找到名称匹配的cookie
    for (var i = 0; i < cookies.length; i++) {
        var cookiePair = cookies[i].split('='); // 分割每个cookie的名字和值
        if (cookiePair[0] === name) {
            return cookiePair[1]; // 返回匹配cookie的值
        }
    }
    // 如果没有找到，返回空串或null
    return '';
}

function jsApiPay(info) {
    function onBridgeReady() {
        if (typeof window.WeixinJSBridge === "undefined") return

        window.WeixinJSBridge.invoke('getBrandWCPayRequest', info,
            function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
            });
    }
    if (typeof window.WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
    } else {
        onBridgeReady();
    }
}

// 当返回为空时自行不需要处理，当返回为字符串时，则显示二维码
export async function payOrder(order_number, wait_cb) {
    let pay_from = "wx"
    if (isAndroid() || isIOS()) {
        if (isWechat()) {
            pay_from = "wx"
        } else {
            pay_from = "h5"
        }
    } else {
        pay_from = "pc"
    }

    // 取得支付平台订单号
    const res = await callApi("platform_order_number", { order_number, pay_from })
    if (res.status != 0) {
        alert(res.msg)
        return
    }

    const platform_order_num = res.data

    const wait_pay = () => {
        // 等待支付完成
        callProxyApi("/wx_pay_wait/pay/wait", { server: "a_xia_yun", out_trade_no: platform_order_num, pay_type: "wx", fee: 100 }).then(res => {
            if (res.status != 0) {
                console.log("wait_pay", res.msg)
                if (res.status === -5) { // 超过了10分钟，取消支付
                    wait_cb()
                }
                return;
            }
            if (wait_cb) {
                wait_cb(res.data)
            }
        }).catch(e => {
            //alert(e)
            wait_cb()
        })

    }

    if (isAndroid() || isIOS()) {
        if (pay_from === "wx") {
            const openid = getCookie("openid");
            if (!openid) {
                alert("获取 openid 失败")
                return
            }

            // jsapi 支付
            const res = await callApi("wx_pay", { order_number: platform_order_num, pay_from, openid })
            if (res.status != 0) {
                alert(res.msg);
                return;
            }

            const info = JSON.parse(res.data)
            jsApiPay(info)
            wait_pay();

        } else {
            // h5 支付
            const res = await callApi("wx_pay", { order_number: platform_order_num, pay_from: "h5" })
            if (res.status != 0) {
                alert(res.msg);
                return;
            }

            window.location.href = res.data
            wait_pay();
            return "h5";
        }
    } else {
        // 二维码支付
        const res = await callApi("wx_pay", { order_number: platform_order_num, pay_from })
        if (res.status != 0) {
            alert(res.msg);
            return;
        }
        wait_pay();
        return res.data;
    }
}

// 统计记录推荐用户
saveRecommandId();

// 获取用户 openid
getUserOpenid();


export const EventBus = new Vue();