let graphql_url = 'http://127.0.0.1:4100';
let chat_url = 'ws://127.0.0.1:9800'
let production = process.env.NODE_ENV === 'production';
// 如果是编译环境
if (production) {
    // video 快速需要完整地址
    graphql_url = 'https://www.axiayun.com';
    //graphql_url = "" //发布之后，接口请求全部通过后端渲染代理
    chat_url = 'wss://api.axiayun.com';
}

const IMAGE_URL = graphql_url;
const FILE_URL = graphql_url;
const API_URL = graphql_url + '/a_xia_yun';
const LANDING_URL = graphql_url + '/luo_di_ye';
const CHAT_WS_URL = chat_url + '/ws';
const CHAT_UPLOAD_URL = graphql_url + '/liao_tian';
const CHAT_PLATFORM = 0x20; //web

const SELECTE_APP_TYPE = "select_app_type";
const SELECTE_MICROSERVICE_TYPE = "select_microservice_type";
const SELECTE_SUPPORT_TYPE = "select_support_type";

const CHAT_TO_USER = "chat_to_user";
const OPEN_DOC = "open_doc";
const EMOJI_ADD = "emoji_add";

const USER_INFO = "user_info";

const SHOW_LOGIN_DIALOG = "show_login_dialog";
const USER_LOGINED = "user_logined";
const USER_LOGOUT = "user_logout";
const HAVE_NEW_MSG = "have_new_msg"; // 有新的聊天消息时显示红点
const OPEN_CHAT_BOX = "open_chat_box"; // 打开聊天窗口
const SET_NEW_CONVERSATION = "set_new_conversation"; //设置了新的会话， 要刷新界面

const SHOW_QRCODE_DIALOG = "show_qrcode_dialog";
const CLOSE_QRCODE_DIALOG = "close_qrcode_dialog";

export {
    IMAGE_URL,
    FILE_URL,
    API_URL,
    LANDING_URL,
    CHAT_WS_URL,
    CHAT_UPLOAD_URL,
    CHAT_PLATFORM,
    SELECTE_APP_TYPE,
    SELECTE_MICROSERVICE_TYPE,
    SELECTE_SUPPORT_TYPE,
    CHAT_TO_USER,
    OPEN_DOC,
    EMOJI_ADD,
    USER_INFO,
    SHOW_LOGIN_DIALOG,
    USER_LOGINED,
    USER_LOGOUT,
    HAVE_NEW_MSG,
    OPEN_CHAT_BOX,
    SET_NEW_CONVERSATION,

    SHOW_QRCODE_DIALOG,
    CLOSE_QRCODE_DIALOG
}
