<template>
    <div
        class="hidden flex flex-col items-center justify-center p-0 bg-gray-100 rounded shadow pb-1 pt-1 md:pb-2  pl-1 md:pl-1 relative chat-area w-xl lg:w-2xl max-w-xl lg:max-w-2xl">
        <div>
            <!-- 关闭按钮 -->
            <button
                class="absolute top-2 right-2 p-2 z-10 circle bg-gray-100 opacity-80 rounded-full w-6 h-6 shadow flex items-center justify-center hover:bg-gray-200 text-gray-500"
                @click="close()">
                x
            </button>

        </div>
        <div class="text-center text-white bg-red-400 cursor-pointer absolute top-2 py-1 px-2 z-10 rounded shadow"
            v-if="chatConnError" @click="createChat()">{{
                chatConnError }},点击重连接</div>

        <div class="flex flex-row w-full " style="height: 50vh">
            <!-- 人员列表 -->
            <div class="w-12 md:w-16" style="height: 50vh">
                <ul class="space-y-2 overflow-auto hide-scrollbar p-1 md:p-2 pb-0 h-full">
                    <li v-for="c in conversations" :key="c.id" class="chat-user cursor-pointer">
                        <div class="indicator">
                            <span v-if="c.have_new"
                                class="indicator-item indicator-bottom indicator-end badge badge-xs badge-error"></span>
                            <div class="avatar placeholder" :class="isOnline(c) ? 'online' : 'offline'"
                                @click="openChat(c.id)">
                                <div v-if="conversationInfo(c)?.avatar" class="w-8 md:w-10 lg:w-12 rounded-full"
                                    :class="{ 'border-red-300 border-2': chatWebsocket?.cur_conversation?.id == c.id }">
                                    <img :src="ImageUrl(conversationInfo(c)?.avatar)" />
                                </div>
                                <div v-else class="bg-neutral text-neutral-content rounded-full w-8 md:w-10 lg:w-12">
                                    <span class="text-md md:text-lg lg:text-xl">{{ conversationInfo(c)?.nick?.charAt(0)
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- 聊天记录显示区域 -->
            <div class="grow overflow-auto w-full p-4 border rounded bg-white h-full chat-messages ">

                <div v-if="chatLogs.length == 0" class="text-center text-gray-500">暂无聊天记录</div>
                <div v-for="chat, i in chatLogs" :key="i" :class="`chat ${isMe(chat) ? 'chat-end' : 'chat-start'}`">
                    <div v-if="isMe(chat)" class="chat-image placeholder avatar">
                        <div v-if="userInfo.avatar" class="w-8 md:w-10 lg:w-12 rounded-full">
                            <img :src="ImageUrl(userInfo.avatar)" />
                        </div>
                        <div v-else class="bg-neutral text-neutral-content rounded-full w-8 md:w-10 lg:w-12">
                            <span class="text-md md:text-lg lg:text-xl">{{ userInfo.nick?.charAt(0) }}</span>
                        </div>
                    </div>
                    <div v-else class="chat-image placeholder avatar">
                        <div v-if="conversationInfo(chatWebsocket.cur_conversation)?.avatar"
                            class="w-8 md:w-10 lg:w-12 rounded-full">
                            <img :src="ImageUrl(conversationInfo(chatWebsocket.cur_conversation)?.avatar)" />
                        </div>
                        <div v-else class="bg-neutral text-neutral-content rounded-full w-8 md:w-10 lg:w-12">
                            <span class="text-md md:text-lg lg:text-xl">{{
                                conversationInfo(chatWebsocket.cur_conversation)?.nick?.charAt(0) }}</span>
                        </div>
                    </div>
                    <div v-if="isMe(chat)" class="chat-header text-xs opacity-30">
                        {{ userInfo.nick }}
                        <time class="text-xs opacity-50">{{ formatRelativeTime(chat.send_time) }}</time>
                    </div>
                    <div v-else class="chat-header text-xs opacity-30">
                        {{ conversationInfo(chatWebsocket.cur_conversation)?.nick }}
                        <time class="text-xs opacity-50">{{ formatRelativeTime(chat.send_time) }}</time>
                    </div>
                    <div
                        class="chat-bubble min-h-6 md:min-h-11 py-1 md:py-2 px-2 md:px-4 text-sm md:text-base rounded-lg md:rounded-xl lg:rounded-2xl">
                        <span v-if="chat.type == 'text'"> {{ chat.data }}</span>
                        <div v-if="chat.type == 'image'">

                            <img :src="ImageUrl(chat.data)" class="rounded-lg cursor-pointer"
                                @click="zoomImage(ImageUrl(chat.data))" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 聊天输入框以及发送图片和文件的按钮 -->
        <div class="flex items-center gap-2 mt-1 w-full">
            <!-- 上传文件按钮 -->
            <!-- <input type="file" id="fileInput" class="hidden" @change="sendFile" accept="*" />
            <label for="fileInput" class="btn cursor-pointer">
                📁
            </label> -->

            <!-- 上传图片按钮 -->
            <input type="file" id="imageInput" class="hidden" @change="sendImage" accept="image/*" />
            <label for="imageInput" class="btn btn-circle cursor-pointer btn-sm md:btn-md">
                <!-- 🏞️ -->
                <svg class="fill-current text-blue-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24">
                    <path
                        d="M21 19V5c0-1.1-.9-2-2-2h-4.18c-.42 0-.81-.24-.95-.63l-.54-1.37C13.1.84 12.65.5 12.16.5H6c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2H19c1.1 0 2-.9 2-2zm-2 0H5V5h2.34l.54 1.37c.14.35.53.59.95.63H19v12z" />
                    <path d="M8.5 13l1.5 2 2-2.5L15 17H5z" />
                    <circle cx="16.5" cy="6.5" r="1.5" />
                </svg>
            </label>

            <input type="text" placeholder="Type here"
                class="grow input focus:outline-none input-accent w-full focus:border-blue-400 input-sm md:input-md"
                v-model="newMessage" @focus="inputFocused = true" @blur="inputFocused = false"
                :class="inputFocused ? 'border-blue-400' : ''" @keyup.enter="sendMessage" />

            <!-- 发送消息按钮，使用 Unicode 飞机图标 -->
            <button @click="sendMessage" class="btn btn-circle btn-sm lg:btn-md">
                <!-- ✈️ -->
                <svg class="fill-current text-blue-500" width="24" height="24" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
                </svg>
            </button>
        </div>
    </div>
</template>
  
<script>

import { HAVE_NEW_MSG, USER_LOGINED, USER_LOGOUT, CHAT_TO_USER, OPEN_CHAT_BOX, CHAT_PLATFORM, CHAT_WS_URL } from '../js/const.js';
import { getLocalUserInfo, sendFile, EventBus, showLoginDialog, formatRelativeTime, ImageUrl } from '../js/common.js';
import { ChatWebSocket, GET_MY_CONVERSATION_LIST, GET_CHAT_LOG, MEMBER_ONLINE, MEMBER_OFFLINE, SINGLE_CHAT_TYPE } from '../js/chat-socket.js';
import Mixins from '../js/mixins.js';

export default {
    data() {
        return {
            chatWebsocket: null,
            newMessage: '',
            userInfo: {},
            inputFocused: false,
            // 假设你有一个聊天数组来存储对话和元数据
            conversations: [],
            chatLogs: [],
            userMap: {},
            chatConnError: '未连接',
        };
    },
    created() {
        EventBus.$on(USER_LOGINED, this.createChat);
        EventBus.$on(USER_LOGOUT, this.onUserLogout)
        EventBus.$on(OPEN_CHAT_BOX, this.onOpenChatBox)
        EventBus.$on(CHAT_TO_USER, this.onChatToUser)
    },
    beforeDestroy() {
        EventBus.$off(USER_LOGINED, this.createChat);
        EventBus.$off(USER_LOGOUT, this.onUserLogout)
        EventBus.$off(OPEN_CHAT_BOX, this.onOpenChatBox)
        EventBus.$off(CHAT_TO_USER, this.onChatToUser)
    },
    mounted() {
        if (!getLocalUserInfo()) {
            return;
        }
        this.createChat();
    },
    mixins: [Mixins],
    methods: {
        onUserLogout() {
            this.chatWebsocket?.close();
            this.conversations = [];
            this.chatLogs = [];
            document.querySelector('.chat-area').classList.add('hidden');
        },
        onOpenChatBox() {
            document.querySelector('.chat-area').classList.remove('hidden');
            this.chatWebsocket.setChatBoxOpened(true)
            this.chatWebsocket.openChatWithNewMsg();
        },
        onChatToUser(id) {
            console.log("chat id", id);
            // 未登录则需要登录
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }

            document.querySelector('.chat-area').classList.remove('hidden');
            this.chatWebsocket.setChatBoxOpened(true)
            if (this.chatWebsocket.openUserChat(id)) {
                this.chatLogs = this.chatWebsocket?.cur_conversation?.chat_log?.slice().reverse() || [];
            }

            setTimeout(() => {
                this.scrollChatToBottom();
            }, 200)
        },
        async createChat() {
            // chat_login
            const res = await this.callApi("chat_login", {}, "chat_login");
            if (res.status != 0) {
                console.error("falied chat_login: ", res.data)
                return
            }

            this.userInfo = res.data;

            // 在 websocket 地址和服务地址不一样时，需要通过 query 参数传递
            const userInfo = this.userInfo;
            let queryParams = {
                platform: CHAT_PLATFORM,
                restful_hash: userInfo.restful_hash,
                restful_user: userInfo.restful_user
            }
            const queryString = new URLSearchParams(queryParams).toString();
            // 连接到服务器，获取聊天记录
            if (!this.$chatSocket.isOpened()) {
                this.$chatSocket.setLoginInfo(CHAT_WS_URL + "?" + queryString, userInfo.id, userInfo.chat_server_id);
                this.$chatSocket.connect();
            } else {
                this.chatConnError = ''
            }
            // 每次组件初始化都需要重新设置
            this.$chatSocket.setChatCallbacks((id) => {
                EventBus.$emit(HAVE_NEW_MSG, id);
            }, (id) => {
                //SET_NEW_CONVERSATION
                this.conversations = [...this.$chatSocket?.user_conversations];
                this.chatLogs = this.$chatSocket?.cur_conversation?.chat_log?.slice().reverse() || [];
                this.scrollChatToBottom();
            });

            this.conversations = [...this.$chatSocket?.user_conversations];
            this.chatLogs = this.$chatSocket?.cur_conversation?.chat_log?.slice().reverse() || [];
            this.scrollChatToBottom();

            this.chatWebsocket = this.$chatSocket;

            this.chatWebsocket.onmessage((msg) => {
                console.log('chat box received message:', msg);
                if (msg.cmd === GET_MY_CONVERSATION_LIST) {
                    // 刷新降天列表
                    this.conversations = this.chatWebsocket.user_conversations;
                } else if (msg.cmd === GET_CHAT_LOG) {
                    this.chatLogs = this.chatWebsocket?.cur_conversation?.chat_log?.slice().reverse() || []; // 使用slice()来创建一个数组的副本
                    this.scrollChatToBottom(true);
                } else if (msg.cmd === MEMBER_ONLINE || msg.cmd === MEMBER_OFFLINE) {
                    this.userMap = { ...this.chatWebsocket.user_map }
                    console.log("user map", this.userMap);
                }
            });
            this.chatWebsocket.onclose(() => {
                console.log('Connection closed');
                this.chatConnError = this.chatWebsocket?.last_errmsg || '已下线';
            });
            this.chatWebsocket.onopen(() => {
                this.chatConnError = '';
                const box_opened = !document.querySelector('.chat-area').classList.contains('hidden');
                this.chatWebsocket.setChatBoxOpened(box_opened)
                console.log('Connection opened');
            })
        },
        openChat(conversion_id) {
            this.chatWebsocket.openConversation(conversion_id);
            this.chatLogs = this.chatWebsocket?.cur_conversation?.chat_log?.slice().reverse() || [];
            this.scrollChatToBottom(false);
        },
        isMe(chatLog) {
            return chatLog.send_id == this.chatWebsocket?.user_id;
        },
        isOnline(conversation) {
            return this.userMap[conversation.info.id]?.online;
        },
        conversationInfo(conversation) {
            if (conversation.type == SINGLE_CHAT_TYPE && conversation.info) {
                return this.chatWebsocket.user_map[conversation.info?.id]
            } else {
                return conversation.info
            }
        },
        sendMessage() {
            if (this.newMessage.trim() === '') return;
            if (this.chatWebsocket.sendChatMsg(this.newMessage)) {
                this.newMessage = '';
            }
        },
        sendFile(event) {
            // 处理文件发送的逻辑
            const file = event.target.files[0];

            if (file) {
                // 这里需要执行发送文件的操作
                console.log('文件将被发送:', file.name);
                // 重置输入字段以确保可以再次选择同一文件
                event.target.value = null;

                sendFile(file).then(res => {
                    console.log("send file", res);
                })
            }

            // 重置输入字段以确保可以再次选择同一文件
            event.target.value = null;
        },
        sendImage(event) {
            // 处理图像发送的逻辑
            const file = event.target.files[0];

            if (file && file.type.startsWith('image/')) {
                // 这里需要执行发送图片的操作
                console.log('图像将被发送:', file.name);
                event.target.value = null;

                sendFile(file).then(res => {
                    console.log("send file", res);
                    if (res.status != 0) {
                        alert(res.msg);
                        return;
                    }
                    this.chatWebsocket.sendChatMsg(res.data.value, "image")
                })
            }

            // 重置输入字段以确保可以再次选择同一文件
            event.target.value = null;
        },
        scrollChatToBottom(animat = false) {
            setTimeout(() => {
                const chatContainer = this.$el.querySelector('.chat-messages');
                // 使用动画效果，将滚动条滚动到最底部
                if (animat) {
                    chatContainer.style.scrollBehavior = 'smooth';
                } else {
                    chatContainer.style.scrollBehavior = 'unset';
                }

                chatContainer.scrollTop = chatContainer.scrollHeight;
            }, 100);
        },
        close() {
            document.querySelector('.chat-area').classList.add('hidden');
            this.chatWebsocket.setChatBoxOpened(false)
        },
        zoomImage(url) {
            // 全局插入一个弹窗，显示图片，并且有关闭按钮
            const zoomImageDialog = document.createElement('div');
            zoomImageDialog.classList.add('fixed', 'inset-0', 'flex', 'items-center', 'justify-center', 'bg-black', 'bg-opacity-50', 'z-50');

            const image = document.createElement('img');
            image.src = url;
            image.classList.add('max-w-full', 'max-h-full', 'object-contain');

            zoomImageDialog.addEventListener('click', (elm) => {
                zoomImageDialog.remove();
            });

            zoomImageDialog.appendChild(image);
            document.body.appendChild(zoomImageDialog);
        }
    },
};
</script>
  
  <!-- Tailwind CSS 2.x 必须在你的项目中可用 -->
<style scoped>
.hide-scrollbar::-webkit-scrollbar {
    display: none;
    /* 针对 Webkit 浏览器 */
}

.hide-scrollbar {
    -ms-overflow-style: none;
    /* 针对 IE、Edge 浏览器 */
    scrollbar-width: none;
    /* 针对 Firefox 浏览器 */
}

@media (min-width: 184px) {
    .chat-area {
        min-width: 100vw;
    }
}

/* 你可以根据需要添加一些自定义样式 */
/* Default styles for extra small devices */
@media (min-width: 384px) {
    .chat-area {
        min-width: 100vw;
    }

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .chat-area {
        min-width: 36rem;
    }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .chat-area {
        min-width: 42rem;
    }

}
</style>