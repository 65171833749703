<template>
    <div>
        <div id="qs-header">
            <qs-header cur-menu="user_center"></qs-header>
        </div>
        <div class="max-w-7xl px-4 lg:px-8 flex flex-col mx-auto pt-10 ">

            <div id="user-center">
                <user-center></user-center>
            </div>
        </div>
    </div>
</template>


<script>
import QsHeader from '../qs-header.vue';
import UserCenter from '../user-center.vue';

export default {
    components: {
        "qs-header": QsHeader,
        "user-center": UserCenter,
    }
}
</script>