<template>
    <div class="lg:block">
        <div class="h-16 min-h-16 lg:h-0 lg:min-h-0"></div>
        <ul class="menu bg-base-200 w-56 lg:rounded-box bg-white" style="z-index:-10;">
            <li v-for="item in docs" :key="item.id">

                <router-link v-if="!item.children || !item.children.length" :to="`/doc/${item.id}`">
                    <span :class="{ 'text-blue-500': item.id == selectedId }">
                        {{ item.title }}
                    </span>
                </router-link>

                <details v-if="item.children && item.children.length" open>
                    <summary>{{ item.title }}</summary>
                    <ul>
                        <li v-for="child in item.children" :key="child.id">
                            <router-link :to="`/doc/${child.id}`" v-if="!child.children">
                                <span :class="{ 'text-blue-500': child.id == selectedId }">
                                    {{ child.title }}
                                </span>
                            </router-link>
                            <details v-if="child.children" open>
                                <summary>{{ child.title }}</summary>
                                <ul>
                                    <li v-for="grandchild in child.children" :key="grandchild.id">
                                        <router-link :to="`/doc/${grandchild.id}`" v-if="!grandchild.children">
                                            <span :class="{ 'text-blue-500': child.id == selectedId }">{{ grandchild.title
                                            }}</span>
                                        </router-link>

                                    </li>
                                </ul>
                            </details>
                        </li>
                    </ul>
                </details>
            </li>
        </ul>
    </div>

    <!-- HTML -->
</template>

<script>
import { callApi, EventBus } from '@/js/common.js';
import { OPEN_DOC } from '@/js/const';

export default {
    watch: {
        $route(to, from) {
            if (to.path.startsWith("/doc")) {
                this.selectItem(to.params.id);
            }
        }
    },
    data() {
        return {
            selectedId: 0,
            docs: [
                // {
                //     "children": [],
                //     "id": 1,
                //     "title": "安装使用"
                // },
                // {
                //     "children": [],
                //     "id": 2,
                //     "title": "导入应用"
                // },
                // {
                //     "children": [
                //         {
                //             "id": 4,
                //             "parent_id": 3,
                //             "title": "简单应用创建"
                //         }
                //     ],
                //     "id": 3,
                //     "title": "创建应用"
                // }
            ]

        };
    },
    mounted() {
        callApi(208, {}, `get_doc_tree`).then((res) => {
            if (res.status < 0) {
                alert(res.msg);
                return;
            }

            this.docs = res.data;
            let id = this.$route.params.id;
            if (!id) {
                // 内容直接打开上一次的
                let id = localStorage.getItem("last_doc_id");
                if (id) {
                    this.selectedId = id;
                } else if (this.docs.length > 0) {
                    this.selectedId = this.docs[0].id;
                }

                //location.href = "/doc/" + this.selectedId
                this.$router.push({ name: "doc-detail", params: { id: this.selectedId } })
            } else {
                this.selectedId = id
            }

        })
    },
    methods: {
        selectItem(id) {
            if (!id) return;

            console.log("old selectedId", this.selectedId, "new", id);
            this.selectedId = id;
            localStorage.setItem("last_doc_id", id);
            EventBus.$emit(OPEN_DOC, id);
        },

    }
}
</script>