import {
    ImageUrl, FileUrl, formatRelativeTime, getRemainDays,
    updateRichTextPaths, copyCurrentLink, callProxyApi,
    callApi, setSiteInfo, getRecommandId, showLoginDialog
} from "@/js/common";

export default {
    data() {
        return {
        };
    },
    methods: {
        ImageUrl,
        FileUrl,
        formatRelativeTime,
        updateRichTextPaths,
        copyCurrentLink,
        callProxyApi,
        callApi,
        setSiteInfo,
        getRemainDays,
        getRecommandId,
        showLoginDialog,
    }
}