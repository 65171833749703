
// 直接发给 status server 的消息类型
const MEMBER_ADD = "member_add"
const MEMBER_REMOVE = "member_remove"
const MEMBER_ONLINE = "member_online"
const MEMBER_OFFLINE = "member_offline"
const MEMBER_SET_STATUS = "member_set_status"
const MEMBER_ADD_STATUS = "member_add_status"
const MEMBER_REMOVE_STATUS = "member_remove_status"
const MEMBER_SET_UPDATE_TIME = "member_set_update_time"
const MEMBER_ATTENTION_LIST = "member_attention_list"
const MEMBER_ADD_ATTENTION = "member_add_attention"
const MEMBER_REMOVE_ATTENTION = "member_remove_attention"
const MEMBER_REQUEST_MEMBER_INFO = "member_request_member_info"
const MEMBER_RESPONSE_MEMBER_INFO = "member_response_member_info"
const MEMBER_RESPONSE_GROUP_INFO = "member_response_group_info"
const MEMBER_GROUP_LIST = "member_group_list"
const MEMBER_ADD_GROUP = "member_add_group"
const MEMBER_REMOVE_GROUP = "member_remove_group"
const MEMBER_FOLLOW_LIST = "member_follow_list"
const MEMBER_ADD_FOLLOW = "member_add_follow"
const MEMBER_REMOVE_FOLLOW = "member_remove_follow"
const MEMBER_SET_ATTENTION_STATUS = "member_set_attention_status"
const MEMBER_ADD_ATTENTION_STATUS = "member_add_attention_status"
const MEMBER_REMOVE_ATTENTION_STATUS = "member_remove_attention_status"
const MEMBER_SET_ATTENTION_UPDATE_TIME = "member_set_attention_update_time"
const MEMBER_SET_GROUP_INFO = "member_set_group_info"
const MEMBER_TRANSFER_MSG = "member_transfer_msg"
const MEMBER_REQUEST_MEMBER_CRC_DETAIL = "member_request_member_crc_detail"

const GROUP_SET_MEMBER_LIST = "group_set_member_list"
const GROUP_ADD_MEMBER = "group_add_member"
const GROUP_REMOVE_MEMBER = "group_remove_member"
const GROUP_SET_MEMBER_STATUS = "group_set_member_status"
const GROUP_ADD_MEMBER_STATUS = "group_add_member_status"
const GROUP_REMOVE_MEMBER_STATUS = "group_remove_member_status"
const GROUP_SET_MEMBER_LAST_UPDATE = "group_set_member_last_update"
const GROUP_RELATION_REQUEST_GROUP_INFO = "group_relation_request_group_info"
const GROUP_WS_REQUEST_GROUP_INFO = "group_ws_request_group_info"
const GROUP_TRANSFER_GROUP_MSG = "group_transfer_group_msg"

const WS_MEMBER_CRC64 = "ws_member_crc64"
const WS_TRANSFER_MSG = "ws_transfer_msg"
const WS_TRANSFER_ERROR = "ws_transfer_error"
const WS_RESPONSE_GROUP_INFO = "ws_response_group_info"
const WS_HEARTBEAT = "ws_heartbeat"

// 发送给 chat-server 的消息类型
//用户指令
const USER_ADD_STATUS = "user_add_status"
const USER_REMOVE_STATUS = "user_remove_status"
const USER_UPDATE_INFO = "user_update_info"
const USER_SET_DYNINFO = "user_set_dyninfo"

const USER_ADD_ATTENTION = "user_add_attention"
const USER_REMOVE_ATTENTION = "user_remove_attention"
const USER_ADD_BLACKLIST = "user_add_blacklist"
const USER_REMOVE_BLACKLIST = "user_remove_blacklist"
const USER_SEARCH = "user_search"
const USER_GET_INFO = "user_get_info" //获取用户信息

// 对话指令
const CREATE_CONVERSATION = "create_conversation"
const GET_MY_CONVERSATION_LIST = "get_my_conversation_list"
const SET_CONVERSATION_ATTR = "set_conversation_attr"
const SEND_CHAT_MSG = "send_chat_msg"
const SYNC_CHAT_LOG = "sync_chat_log"
const READ_CHAT_LOG = "read_chat_log"   //已读标识
const UPDATE_CHAT_LOG = "update_chat_log" //更新聊天记录状态（交互消息）
const GET_CHAT_LOG = "get_chat_log"    //拉取最新聊天消息及更新未读状态

// 群指令
const CREATE_GROUP = "create_group"
const JOIN_GROUP = "join_group"
const LEAVE_GROUP = "leave_group"
const INVITE_GROUP_MEMBER = "invite_group_member"
const KICK_GROUP_MEMBER = "kick_group_member"

// 离线消息，在用户登录时就应该发送给用户的，用户也需要发送确认消息表示收到过
const REQUEST_JOIN_GROUP = "request_join_group"
const ACCEPT_GROUP_MEMBER = "accept_group_invite"

const MIN_CONVERSATION_ID = 1000000000;
//1000000001

// 会话类型
const SINGLE_CHAT_TYPE = 1
const GROUP_CHAT_TYPE = 0

import { callApi, postFile } from "./common"
import { CHAT_UPLOAD_URL } from "./const"


class ChatWebSocket {
    constructor(protocols) {
        this.url = null;
        this.protocols = protocols;
        this.ws = null;
        this.callbacks = {
            onopen: null,
            onmessage: null,
            onclose: null,
            onerror: null
        };

        this.not_reconnect = false; // 是否不需要重连
        this.heart_beat_timer = null; // 心跳
        this.chat_server_id = null; // 聊天服务器的id

        this.msg_map = {}; // 已经发送的消息， key 为 uuid
        this.user_id = null;  // 用户自己的id
        this.user_status = 0; // 用户当前状态
        this.user_conversations = []; // 用户所有会话，当未拉取时为 []
        this.cur_conversation = null; // 当前会话
        this.user_map = {}; // 用户信息映射，key 为 user_id  
        this.chat_box_opened = false; // 未打开聊天窗口
        this.haveNewMsgCB = null; // 有新消息时的回调
        this.setNewConversation = null; // 有新会话时的回调

        // 业务id与聊天id的映射，key 为业务id
        this.user_chat_id_map = {}

        this.last_errmsg = ''

    }
    setLoginInfo(url, user_id, chat_server_id) {
        this.url = url
        this.user_id = user_id
        this.chat_server_id = chat_server_id
    }
    setChatCallbacks(haveNewMsgCB, setNewConversation,) {
        this.haveNewMsgCB = haveNewMsgCB
        this.setNewConversation = setNewConversation
    }
    connect() {
        this._begin_connect()
    }
    setChatBoxOpened(opened) {
        this.chat_box_opened = opened;
        if (opened) {
            this.haveNewMsgCB(null)
        }
    }
    _begin_connect() {
        this.ws = new WebSocket(this.url, this.protocols);

        this.ws.onopen = (event) => {
            clearInterval(this.heart_beat_timer);
            // 发送心跳消息, 30秒一次，服务端是60秒超时,10秒检测一次
            this.heart_beat_timer = setInterval(() => {
                if (!this.isOpened()) {
                    return;
                }

                this.ws.send(JSON.stringify({ cmd: WS_HEARTBEAT }));
            }, 30 * 1000);

            // 取得自己的所有会话
            this.sendMsg({
                cmd: GET_MY_CONVERSATION_LIST
            })

            if (this.callbacks.onopen) {
                this.callbacks.onopen(event);
            }

        };

        this.ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const user_msg = this._handleMsg(data);
            // 处理消息
            if (this.callbacks.onmessage && user_msg) {
                this.callbacks.onmessage(user_msg);
            }
        };

        this.ws.onclose = (event) => {
            // Check if it was a normal closure, you might want to check event.code
            if (!event.wasClean) {
                console.log("close with error", event);
                this._reconnect();
            }
            if (this.callbacks.onclose) {
                this.callbacks.onclose(event);
            }
        };

        this.ws.onerror = (event) => {
            if (this.callbacks.onerror) {
                this.callbacks.onerror(event);
            }
            this.ws.close();
        };
    }
    _reconnect() {
        // Implement your own backoff strategy here
        // For simplicity, we will just attempt to reconnect immediately
        setTimeout(() => {
            console.log('Reconnecting WebSocket...');
            if (this.not_reconnect) {
                console.log('Not reconnecting webSocket...');
                return;
            }
            this.connect();
        }, 1000); // reconnect after 1 second
    }

    _handleMsg(msg) {
        console.log("handle status message", msg)
        if (msg.errno < 0) {
            console.error("handle status message error", msg.errmsg)
            this.last_errmsg = msg.errmsg
            return;
        }

        const message = JSON.parse(msg.data)
        if (msg.cmd === WS_HEARTBEAT) {
            // 心跳
            this.status = message.status
        } else if (msg.cmd === WS_TRANSFER_MSG) {
            // 消息同步
            if (message.fromId !== this.chat_server_id) {
                return
            }
            const user_msg = JSON.parse(message.msg)
            return this._handleUserMsg(user_msg);

        } else if (msg.cmd === WS_TRANSFER_ERROR) {
            // 错误
            if (this.msg_map[msg.uuid]) {
                console.error("WS_TRANSFER_ERROR", message, this.msg_map[msg.uuid])
            }

        } else if (msg.cmd == WS_MEMBER_CRC64) {
            // 状态同步消息
            if (message.cmd === MEMBER_ONLINE || message.cmd === MEMBER_OFFLINE) {
                const user_id = message.memberInfo?.id;
                const user_status = message.memberInfo?.status;

                if (message.cmd === MEMBER_ONLINE && user_id == this.user_id
                    && message.haveDetail && message.attentionList) {
                    // 更新自己的关注用户列表
                    for (let a of message.attentionList) {
                        this.user_map[a.id] = a
                        if (a.status !== null) {
                            this.user_map[a.id].online = a.status & 0x1 > 0
                        }
                    }
                }

                if (user_id && user_status !== null) {
                    this.user_map[user_id] = { ...this.user_map[user_id], online: user_status & 0x1 > 0 };
                    return {
                        cmd: message.cmd,
                        memberInfo: this.user_map[user_id]
                    }
                }
            } else if (message.cmd === MEMBER_SET_ATTENTION_UPDATE_TIME) {
                const memberInfo = message.memberInfo;
                if (memberInfo.id >= MIN_CONVERSATION_ID) { // 是会话消息更新了
                    // 更新会话的 lastUpdate
                    this.user_conversations = this.user_conversations.map(it => {
                        if (it.id === memberInfo.id) {
                            it.last_update = memberInfo.lastUpdate;
                        }
                        return it;
                    })

                    // 可以去取最新消息
                    this.sendMsg({
                        cmd: GET_CHAT_LOG,
                        data: {
                            conversation_id: memberInfo.id,
                            last_update: memberInfo.lastUpdate, // status 中的消息是 lastUpdate, chat 中消息是 last_update
                            limit: 1000,
                            offset: 0
                        }
                    })
                } else {
                    // 更新用户信息
                    this.syncUserInfo([memberInfo.id])
                }
            }
        }
    }
    _handleUserMsg(msg) {
        console.log("handler chat server message", msg)
        if (msg.cmd === GET_MY_CONVERSATION_LIST) {
            let user_id_list = [];
            this.user_conversations = msg.data.map(it => {
                // conversation，合并到 user_conversation中
                let info = { ...it, online: 0, ...it.conversation }
                if (info.conversation.type == SINGLE_CHAT_TYPE) {
                    const user_info = info.conversation.info;
                    this.user_map[user_info.id] = { ...this.user_map[user_info.id], ...user_info }
                    user_id_list.push(user_info.id)
                }
                // 删除 conversation key
                delete info.conversation
                return info;
            });

            // 重连时，设置当前会话
            if (this.cur_conversation !== null) {
                for (let c of this.user_conversations) {
                    if (c.id == this.cur_conversation.id) {
                        c.chat_log = this.cur_conversation.chat_log
                        this.setCurConversation(c)
                        break
                    }
                }
            } else {
                if (this.user_conversations.length > 0 && this.chat_box_opened) {
                    this.setCurConversation(this.user_conversations[0])
                }
            }

            this.syncUserInfo(user_id_list)

            //console.log("user_conversations", JSON.stringify(this.user_conversations, null, 2))
        } else if (msg.cmd === USER_GET_INFO) {
            this.user_map[msg.data.id] = msg.data
        } else if (msg.cmd === CREATE_CONVERSATION) {
            let info = { ...msg.data, online: 0, ...msg.data.conversation }
            if (info.conversation.type == SINGLE_CHAT_TYPE) {
                // 删除 value 为空的值
                const user_info = info.conversation.info;
                this.user_map[user_info.id] = { ...this.user_map[user_info.id], ...user_info }
                this.syncUserInfo([user_info.id])
            }
            this.user_conversations.unshift(info);
            this.setCurConversation(info)
        } else if (msg.cmd === GET_CHAT_LOG) {
            for (let c of this.user_conversations) {
                if (c.id === msg.data.conversation_id && msg.data.chat_log.length > 0) {
                    if (!c.chat_log) {
                        c.chat_log = []
                    } else if (c.chat_log.length > 0 && c.chat_log[0].last_update >= msg.data.chat_log[msg.data.chat_log.length - 1].last_update) {
                        // 已经是最新消息
                        return null;
                    }

                    c.chat_log = [...msg.data.chat_log, ...c.chat_log];
                    if (this.cur_conversation?.id !== c.id || !this.chat_box_opened) {
                        c.have_new = true;
                    }

                    if (!this.chat_box_opened) {
                        this.haveNewMsgCB(c.id);
                    }
                    break
                }
            }


        } else {
            return null;
        }
        // 用于刷新界面
        return msg;
    }
    setCurConversation(conversation) {
        conversation.have_new = false;
        if (this.cur_conversation === conversation) {
            return;
        }
        // 切换了会话
        this.cur_conversation = conversation;
        // 将 cur_conversation 移到第一个
        this.user_conversations = [conversation, ...this.user_conversations.filter(c => c.id !== conversation.id)];

        if (this.cur_conversation.last_log !== null && !this.cur_conversation.chat_log) {
            this.sendMsg({
                cmd: GET_CHAT_LOG,
                data: {
                    conversation_id: this.cur_conversation.id,
                    last_update: 0,
                    limit: 100,
                    offset: 0
                }
            })
        }

        this.setNewConversation(conversation.id);
    }
    syncUserInfo(user_id_list) {
        // 同步用户信息，从业务服务端拉取用户信息，然后同步到 user_map 中
        callApi("chat_user_info_by_ids", { ids: user_id_list }, "取用户信息").then(res => {
            if (res.status === 0) {
                const user_info_list = res.data.map(it => {
                    this.user_chat_id_map[it.id] = it.chat_server_id
                    return {
                        id: it.chat_user_id,
                        nick: it.nick,
                        avatar: it.avatar
                    }
                })
                for (let user of user_info_list) {
                    this.user_map[user.id] = { ...this.user_map[user.id], ...user }
                }
                this.setNewConversation(); // 刷新界面
            } else {
                console.error("取用户信息失败", res)
            }
        })
    }
    openChatWithNewMsg() {
        // 优化打开有消息的会话
        for (let c of this.user_conversations) {
            if (c.have_new) {
                c.have_new = false;
                if (c !== this.cur_conversation) {
                    this.setCurConversation(c)
                }
                return c
            }
        }

        if (this.user_conversations.length > 0 && !this.cur_conversation) {
            this.setCurConversation(this.user_conversations[0])
            return this.user_conversations[0]
        }
    }
    // 打开会话
    openConversation(conversation_id) {
        for (let c of this.user_conversations) {
            if (c.id === conversation_id) {
                this.setCurConversation(c)
                return c
            }
        }
    }
    // 打开单聊
    async openUserChat(recv_id) {
        // 根据需要单聊的用户，如果会话已经存在，则返回会话，如果不存在，则返回会空并且新建会话
        if (!recv_id) {
            alert("请选择聊天对象")
            return;
        }

        // 转换为聊天id
        if (this.user_chat_id_map[recv_id]) {
            recv_id = this.user_chat_id_map[recv_id]
        } else {
            const res = await callApi("chat_user_id", { user_id: recv_id }, "get_chat_user_id")
            if (res.status === 0) {
                this.user_chat_id_map[recv_id] = res.data
                recv_id = res.data
            } else {
                console.error("get_chat_user_id error", res)
                alert("用户不存在")
                return
            }
        }

        if (recv_id == this.user_id) {
            //alert("不能和自己聊天")
            if (this.user_conversations.length > 0 && !this.cur_conversation) {
                this.setCurConversation(this.user_conversations[0])
                return this.user_conversations[0]
            }
            return;
        }

        if (this.cur_conversation && this.cur_conversation.type === SINGLE_CHAT_TYPE
            && (this.cur_conversation.recv_id === recv_id || this.cur_conversation.user_id === recv_id)) {
            return this.cur_conversation;
        }

        for (let c of this.user_conversations) {
            if (c.type === SINGLE_CHAT_TYPE && (c.recv_id === recv_id || c.user_id === recv_id)) {
                this.setCurConversation(c)
                return c
            }
        }

        this.sendMsg({
            cmd: CREATE_CONVERSATION,
            data: {
                type: SINGLE_CHAT_TYPE, // 如果是 GROUP_CHAT_TYPE 则表示加入 群聊, recv_id 为 group_id
                recv_id
            }
        })
    }
    sendChatMsg(msg, type = "text") {
        return this.sendMsg({
            cmd: SEND_CHAT_MSG,
            data: {
                conversation_id: this.cur_conversation.id,
                type: type,
                data: msg
            }
        })
    }
    // Set callback functions
    onopen(callback) {
        this.callbacks.onopen = callback;
    }

    onmessage(callback) {
        this.callbacks.onmessage = callback;
    }

    onclose(callback) {
        this.callbacks.onclose = callback;
    }

    onerror(callback) {
        this.callbacks.onerror = callback;
    }

    // Send a message through the WebSocket
    sendMsg(data) {
        // data 为 user_message 对象，专门和 chat_server 通讯的
        if (this.ws.readyState !== WebSocket.OPEN) {
            console.error('WebSocket is not connected');
            return false;
        }

        if (!this.chat_server_id) {
            console.error('chat_server_id is null');
            return false;
        }

        // 这是真正的 pb.TransferMsg
        let tmpMsg = {
            ids: [this.chat_server_id],
            fromId: this.user_id,
            msg: JSON.stringify(data)
        }

        console.log("sendMsg", tmpMsg);

        // 这是发给 websocket  的消息 client_request
        let msg = {
            cmd: WS_TRANSFER_MSG,
            uuid: this.uuid(),
            data: JSON.stringify(tmpMsg)
        }
        // 记录消息
        this.msg_map[msg.uuid] = tmpMsg;
        this.ws.send(JSON.stringify(msg));

        return true;
    }

    // Get current WebSocket state
    getState() {
        return this.ws ? this.ws.readyState : WebSocket.CLOSED;
    }

    isOpened() {
        console.log("websocket state", this.ws?.readyState);
        return this.ws ? this.ws.readyState === WebSocket.OPEN : false;
    }

    // Close the WebSocket connection
    close() {
        if (this.ws) {
            this.ws.close();
            console.log('WebSocket closed');
            this.not_reconnect = true;
        }
    }
    uuid() {
        // 创建一个 uuid
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    sendFile(file) {
        // 只有以chat的账号密码登录了才能上传
        return postFile(CHAT_UPLOAD_URL + "/upload", file)
    }

}



// 常量全部导出
export {
    ChatWebSocket,
    GET_MY_CONVERSATION_LIST,
    GET_CHAT_LOG,
    MEMBER_ONLINE,
    MEMBER_OFFLINE,
    SINGLE_CHAT_TYPE,
    GROUP_CHAT_TYPE,
}