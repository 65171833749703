<template>
    <div>
        <button class="btn btn-ghost btn-circle avatar" onclick="login_model.showModal()">登录</button>
        <dialog id="login_model" class="modal">
            <form method="dialog" class="modal-backdrop">
                <button>close</button>
            </form>
            <div class="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100 modal-box">
                <form class="card-body">
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">手机号</span>
                        </label>
                        <input type="tel" placeholder="请输入手机号" class="input input-bordered" v-model="phoneNumber"
                            required />
                    </div>
                    <div class="form-control">
                        <label class="label">
                            <span class="label-text">验证码</span>
                        </label>
                        <div class="flex flex-row gap-2">
                            <input type="text" placeholder="请输入验证码" class="input input-bordered w-40"
                                v-model="verificationCode" required />
                            <button type="button" class="btn" :disabled="timer > 0" @click="sendCode">发送{{ timer > 0 ? `
                                (${timer})` : '验证码' }}</button>
                        </div>
                    </div>
                    <!-- 同意协议复选框 -->
                    <div class="form-control mt-6">
                        <label class="cursor-pointer label flex justify-start">
                            <input type="checkbox" class="checkbox" v-model="agreedToTerms" required />
                            <div class="label-text ml-2">我已阅读并同意
                                <a @click.stop="onShowAgreement()">相关协议</a>
                            </div>
                        </label>
                    </div>
                    <div class="form-control">
                        <button type="button" class="btn btn-primary" @click.stop="login()">登录</button>
                    </div>
                </form>
            </div>
        </dialog>
        <dialog ref="agreement" class="modal">
            <form method="dialog" class="modal-backdrop">
                <button>close</button>
            </form>
            <div v-html="agreement" class="modal-box w-full"></div>
        </dialog>
    </div>
</template>
  
<script>
import { EventBus, userLogin, callProxyApi, postJson } from '../js/common.js';
import { SHOW_LOGIN_DIALOG, USER_INFO, USER_LOGINED, LANDING_URL } from '../js/const.js';

export default {
    data() {
        return {
            phoneNumber: localStorage.getItem("login_mobile"),
            verificationCode: '',
            timer: 0,
            agreedToTerms: localStorage.getItem("agree_terms"), // 新增同意协议标识
            agreement: ''
        };
    },
    created() {
        EventBus.$on(SHOW_LOGIN_DIALOG, this.onShowDialog)
    },
    beforeDestroy() {
        EventBus.$off(SHOW_LOGIN_DIALOG, this.onShowDialog)
    },
    methods: {
        onShowAgreement() {
            postJson(LANDING_URL + '/api/agreement').then(res => {
                if (res.status < 0) {
                    alert(res.msg)
                    return
                }

                this.agreement = res.data.doc
                this.$refs.agreement.showModal()
            })
        },
        onShowDialog() {
            console.log("onShowDialog")
            document.getElementById("login_model").showModal();
        },
        sendCode() {
            // TODO: Implement the logic to send the verification code
            if (this.timer > 0 || this.phoneNumber.length < 11) {
                return
            }

            callProxyApi('/sms/send_code_no_verify/a_xia_yun', {
                mobile: "+86" + this.phoneNumber
            }).then(res => {
                if (res.status < 0) {
                    alert(res.msg)
                }

                // Start a 60 seconds timer
                this.timer = 60;
                const interval = setInterval(() => {
                    this.timer--;
                    if (this.timer === 0) {
                        clearInterval(interval);
                    }
                }, 1000);
            })

        },
        login() {
            console.log("click login: ", this.agreedToTerms)

            userLogin({ mobile: this.phoneNumber, code: this.verificationCode, login_type: "mobile" }).then(res => {
                if (res.status < 0) {
                    alert(res.msg)
                    return
                }

                document.getElementById("login_model").close();
                localStorage.setItem("login_mobile", this.phoneNumber)
                localStorage.setItem("agree_terms", this.agreedToTerms)

                EventBus.$emit(USER_INFO, res.data);
                EventBus.$emit(USER_LOGINED, res.data);
            });


        }
    }
};
</script>