<template>
    <div>
        <dialog id="qrcode_model" class="modal">
            <form method="dialog" class="modal-backdrop">
                <button>close</button>
            </form>

            <div class="card shrink-0 w-80 max-w-fit shadow-2xl bg-base-100 modal-box">
                <div class="flex items-center flex-col gap-y-4">
                    <div>请支付: <span class="text-red-500">{{ money }} ¥</span></div>
                    <qrcode-view :text="text" v-if="text && text!='h5'"></qrcode-view>
                    <button class="btn btn-link no-underline hover:no-underline" @click="callback ? callback() : null">支付完成</button>
                </div>
            </div>
        </dialog>
    </div>
</template>
  
<script>
import { EventBus, userLogin, callProxyApi, postJson } from '../js/common.js';
import { SHOW_QRCODE_DIALOG, CLOSE_QRCODE_DIALOG } from '../js/const.js';
import QrcodeView from './qrcode-view.vue';

export default {
    data() {
        return {
            text: '',
            money: 0,
            callback: null,
        };
    },
    created() {
        EventBus.$on(SHOW_QRCODE_DIALOG, this.onShowDialog)
        EventBus.$on(CLOSE_QRCODE_DIALOG, this.onClodeDialog)
    },
    beforeDestroy() {
        EventBus.$off(SHOW_QRCODE_DIALOG, this.onShowDialog)
        EventBus.$off(CLOSE_QRCODE_DIALOG, this.onClodeDialog)
    },
    methods: {
        onShowDialog(text, money, callback) {
            this.text = text
            this.money = money
            this.callback = callback
            document.getElementById("qrcode_model").showModal();
        },
        onClodeDialog() {
            document.getElementById("qrcode_model").close();
        },
    },
    components: {
        "qrcode-view": QrcodeView,
    }
};
</script>