<template>
    <div class="flex flex-col gap-4 mt-4">
        <div v-if="withdraw_list.length == 0" class="text-center text-gray-500">暂无提现记录</div>
        <div v-for="item in withdraw_list" :key="item.id"
            class="card bg-white shadow hover:shadow-lg transition-shadow duration-300 ease-in-out rounded-lg overflow-hidden">
            <div
                class="flex flex-col md:flex-row justify-between p-4 bg-gray-50 hover:bg-blue-50 transition-colors duration-300 gap-4 md:gap-16">
                <div class="flex flex-col gap-2">
                    <div class="flex flex-wrap gap-8 items-center">
                        <div class="text-sm font-medium text-gray-700">金额: <span class="text-red-500">¥{{ item.amount
                        }}</span></div>
                        <div v-if="item.create_at" class="text-sm font-medium text-gray-700">申请时间: {{
                            formatRelativeTime(item.create_at) }}
                        </div>
                        <div v-if="item.arrive_at" class="text-sm font-medium text-gray-700">到账时间: {{
                            formatRelativeTime(item.arrive_at) }}
                        </div>
                        <div v-else class="text-sm font-medium text-gray-700">
                            状态: <span class="text-red-500">{{ item.status == 0 ? '未到账' : '已到账' }}</span>
                        </div>
                        <div v-if="item.remark" class="text-sm font-medium text-gray-700 flex items-center gap-2">
                            <span>备注:</span>
                            <span>{{ item.remark }}</span>
                        </div>
                        <div v-if="item.opt_user_id"
                            class="flex items-center gap-2 cursor-pointer hover:text-blue-500 transition-colors duration-300"
                            @click.stop="chat(item.opt_user_id)">
                            💬
                            <span class="text-sm text-gray-700 hover:text-blue-500">联系操作人</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    callApi, EventBus
} from '@/js/common';

import { CHAT_TO_USER } from '@/js/const';
import mixins from '@/js/mixins';

export default {
    mixins: [mixins],
    data() {
        return {
            withdraw_list:
                [
                    // {
                    //     "amount": "8000",
                    //     "arrive_at": null,
                    //     "create_at": "2024-01-26 14:08:43",
                    //     "id": 6,
                    //     "opt_user_id": null,
                    //     "payee_account": "mmm",
                    //     "remark": null,
                    //     "remark_at": null,
                    //     "status": 0,
                    //     "user_id": 3
                    // },
                    // {
                    //     "amount": "102",
                    //     "arrive_at": null,
                    //     "create_at": "2024-01-26 12:26:01",
                    //     "id": 1,
                    //     "opt_user_id": null,
                    //     "payee_account": null,
                    //     "remark": null,
                    //     "remark_at": null,
                    //     "status": 1,
                    //     "user_id": 3
                    // }
                ]

        }

    },
    mounted() {
        this.get_withdraw_list();
    },
    methods: {
        click_order(order) {
            console.log("click_order", order);
            if (order.item_type == "AppService") {
                this.$router.push({ name: "app-detail", params: { id: order.info.id } })
            } else if (order.item_type == "MicroService") {
                this.$router.push({ name: "service-detail", params: { id: order.info.id } })
            } else if (order.item_type == "TechService") {
                this.$router.push({ name: "support-detail", params: { id: order.info.id } })
            }
        },
        chat(user_id) {
            try {
                EventBus.$emit(CHAT_TO_USER, user_id);
            } catch (e) {
                console.log("error", e);
            }
        },
        get_star(rating) {
            // 取整数
            return Math.ceil(rating)
        },
        download_try(service) {
            console.log("download_try", service);
        },
        get_withdraw_list() {
            callApi("withdraw_list", {}, `get_withdraw_list`).then((res) => {
                if (res.status < 0) {
                    console.log("withdraw_list error", res.msg)
                    return;
                }

                this.order_list = res.data
            })
        }

    }
}
</script>