<template>
    <div>
        <!-- Video List -->
        <div class="bg-white shadow-md rounded px-4 md:px-8 pt-6 pb-8 mb-4">
            <div class="flex flex-row justify-between items-center">
                <h2 class="text-xl font-bold mb-4">教程</h2>
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                    @click="showModal()">添加</button>
            </div>
            <div v-if="!data_list.length" class="text-center text-gray-500">暂无数据</div>
            <div class="container mx-auto py-8">
                <!-- Mobile view, shown only on small screens -->
                <div class="block lg:hidden xl:hidden xxl:hidden">
                    <!-- Card representation for each item, repeat structure for additional items -->
                    <div v-for="item in data_list" :key="item.id" class="bg-white p-4 rounded-lg shadow mb-4 space-y-2">
                        <div><strong>标题:</strong> {{ item.title }}</div>
                        <div><strong>状态:</strong> {{ item.status_label?.label }}</div>
                        <div><strong>更新时间:</strong> {{ formatRelativeTime(item.update_at) }}</div>
                        <div>
                            <video controls :src="FileUrl(item.video?.video_url)" class="w-full"></video>
                        </div>
                        <div class="flex mt-4 w-full justify-end md:space-x-2 items-center">
                            <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
                                @click="showModal(item.id)">编辑</button>
                            <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                @click="deleteItem(item.id)">删除</button>
                        </div>
                    </div>
                </div>

                <!-- Desktop view, hidden on small screens -->
                <div class="hidden  lg:block xl:block xxl:block">
                    <table v-if="data_list.length > 0" class="table-auto w-full mb-4">
                        <thead>
                            <tr>
                                <th class="border px-4 py-2">标题</th>
                                <th class="border px-4 py-2">视频</th>
                                <th class="border px-4 py-2">状态</th>
                                <th class="border px-4 py-2">上传时间</th>
                                <th class="border px-4 py-2">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data_list" :key="item.id">
                                <td class="border px-4 py-2 text-center">{{ item.title }}</td>
                                <td class="border px-4 py-2 text-center"><video controls
                                        :src="FileUrl(item.video?.video_url)" class="w-64"></video></td>
                                <td class="border px-4 py-2 text-center">{{ item.status_label?.label }}</td>
                                <td class="border px-4 py-2 text-center">{{ formatRelativeTime(item.update_at) }}</td>
                                <td class="border px-4 py-2 text-center space-x-2">
                                    <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
                                        @click="showModal(item.id)">编辑</button>
                                    <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                        @click="deleteItem(item.id)">删除</button>
                                </td>
                            </tr>
                            <!-- Additional rows as needed -->
                        </tbody>
                    </table>
                </div>
            </div>
            <div ref="edit_dialog"
                class="hidden z-10 w-screen h-screen overflow-auto bg-black bg-opacity-50 fixed right-0 left-0 bottom-0 top-0 flex items-center justify-center">
                <div class="card h-5/6 md:h-fit overflow-y-auto shrink-0 w-full max-w-3xl shadow-2xl bg-base-100">
                    <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" @click="closeModal">✕</button>

                    <form class="card-body" @submit.prevent="submitVideo">
                        <h1 class="text-2xl font-bold mb-4">{{ currentData.id ? '修改' : '新增' }}</h1>
                        <div class="form-control">
                            <label class="label block text-sm font-medium text-gray-700">标题</label>
                            <input type="text" class="input input-bordered w-full " placeholder="请输入标题" required
                                v-model="currentData.title">
                        </div>
                        <div class="form-control">
                            <label class="label block text-sm font-medium text-gray-700">选择视频</label>
                            <select class="select select-bordered w-full " required v-model="currentData.video_id">
                                <option v-for="t, i in video_list" :key="i" :value="t.id">{{ t.title }}</option>
                            </select>
                        </div>
                        <div class="form-control">
                            <label class="label">
                                <span class="label-text">文档</span>
                            </label>
                            <Editor :id="editorId" :value="currentData.content" @input="currentData.content = $event" />
                        </div>
                        <button type="submit" class="btn btn-primary text-white">提交</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    callApi, EventBus, getOptions, sendFile
} from '@/js/common';

import Editor from './tiny-editor.vue';
import mixins from '@/js/mixins';

export default {
    mixins: [mixins],
    data() {
        return {
            editorId: "editor",
            types: [],
            // 模拟数据列表
            data_list: [
                // {
                //     id: 1,
                //     title: "Example Video",
                //     video_url: "",
                //     category: "Library",
                //     status: "1.0.0",
                //     update_at: "2023-01-01",
                // },
                // 可以添加更多的包
            ],
            video_list: [],
            // 用于表单的数据
            currentData: {
                title: "",
                video_id: 0,
                content: ""
            }
        };
    },
    async mounted() {
        await this.getVideoList()
        //await this.getTypeList()
        await this.getDataList()

    },
    unmounted() {
        alert("unmounted editor")
    },
    components: {
        Editor,
    },
    methods: {
        async getVideoList() {
            try {
                const res = await callApi("video_list", {}, "get_video_list")
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                }
                this.video_list = res.data
            } catch (e) {
                console.error("get video list error", e)
            }
        },
        async getTypeList() {
            try {
                const res = await getOptions('/videos/category')
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                } else {
                    this.types = res.data.options
                }
            } catch (e) {
                console.error("get type list error", e)
            }
        },
        getTypeName(t) {
            return this.types.find(type => type.value === t)?.label
        },
        async getDataList() {
            try {
                const res = await callApi("get_doc_list")
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                } else {
                    this.data_list = res.data
                }
            } catch (e) {
                console.error("get video list error", e)
            }
        },
        showModal(id) {
            if (id) {
                // 获取指定包的数据
                const dataIndex = this.data_list.findIndex(pkg => pkg.id === id);
                if (dataIndex !== -1) {
                    this.currentData = this.data_list[dataIndex];

                }
            } else {
                // 重置表单数据
                this.currentData = {
                    title: "",
                    video_id: 0,
                    content: ""
                };
            }

            // 打开模态对话框
            this.$refs.edit_dialog.classList.remove('hidden');

        },
        closeModal() {
            // 关闭模态对话框
            this.$refs.edit_dialog.classList.add('hidden');
        },
        async submitVideo() {
            // 提交表单处理方法
            const res = await callApi("upsert_doc", this.currentData, "upsert_doc");
            if (res.status < 0) {
                alert(res.msg)
                return;
            }
            await this.getDataList();
            this.closeModal();
        },
        async deleteItem(videoId) {
            if (!confirm("确定删除该文档吗?")) {
                return;
            }

            // 删除某个包
            const res = await callApi("remove_doc", { id: videoId }, "remove_doc");
            if (res.status < 0) {
                alert(res.msg)
                return;
            }
            await this.getDataList();
        },

        // 可以添加其他方法，如编辑包等
        async uploadVideo(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const file = input.files[0];
                const res = await sendFile(file)
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                }

                this.currentData.video_url = res.data.value;
            }
        }
    }
}
</script>