<template>
    <div class="card overflow-y-auto min-h-min shrink-0 bg-white p-4 md:p-8 flex flex-col w-full max-w-3xl justify-center">
        <!-- <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" @click="closeModal">✕</button> -->
        <div class="w-full max-w-3xl flex justify-end">
            <button class="btn btn-link no-underline hover:no-underline font-normal" @click="returnTo()">返回</button>
        </div>
        <h3 class="text-2xl font-bold text-center mb-4">{{ form.id ? '编辑应用' : '提交应用' }}</h3>
        <form @submit.prevent="onSubmit" class="space-y-2">

            <div class="form-control">
                <label class="label">
                    <span class="label-text">标题</span>
                </label>
                <input type="text" v-model="form.title" placeholder="Title" class="input input-bordered" required />
            </div>

            <div class="form-control">
                <label class="label">
                    <span class="label-text">封面</span>
                </label>
                <div @click="triggerImageInput" class="cursor-pointer">
                    <img :src="form.img ? ImageUrl(form.img) : '/images/placeholder.svg'"
                        class="w-24 h-24 rounded border-2 border-gray-300" />
                    <input type="file" accept="image/*" class="hidden" ref="imageInput" @change="handleImageUpload" />
                </div>
            </div>

            <div class="form-control">
                <label class="label">
                    <span class="label-text">所属类别</span>
                </label>
                <select v-model="form.category" class="select select-bordered" required>
                    <option disabled value="">选择一个分类</option>
                    <!-- 这里你可以插入获取的选项 -->
                    <option v-for="c in categories" :key="c.value" :value="c.value">{{ c.label }}</option>
                    <!-- 其他选项... -->
                </select>
            </div>

            <div class="form-control">
                <label class="label">
                    <span class="label-text">视频</span>
                </label>
                <div class="w-full flex flex-row gap-8">
                    <select v-model="form.video_id" class="flex-1 select select-bordered" required>
                        <option disabled value="">选择一个视频</option>
                        <!-- 这里你可以插入获取的选项 -->
                        <option v-for="v in videos" :key="v.id" :value="v.id">{{ v.title }}</option>
                        <!-- 其他选项... -->
                    </select>
                    <button class="btn  text-gray-600" @click="returnTo('my_video')">添加视频</button>
                </div>
            </div>

            <div class="form-control">
                <label class="label">
                    <span class="label-text">安装包</span>
                </label>
                <div class="w-full flex flex-row gap-4">
                    <select v-model="form.package_id" class="flex-1 select select-bordered" required>
                        <option disabled value="">选择一个安装包</option>
                        <!-- 这里你可以插入获取的选项 -->
                        <option v-for="p in packages" :key="p.id" :value="p.id">{{ p.title }}</option>
                        <!-- 其他选项... -->
                    </select>
                    <button class="btn  text-gray-600" @click="returnTo('my_package')">添加安装包</button>
                </div>
            </div>

            <div class="form-control">
                <label class="label">
                    <span class="label-text">描述</span>
                </label>
                <textarea type="textarea" api-key="no-api-key" v-model="form.desc" placeholder="Description"
                    class="textarea-bordered textarea" required></textarea>
            </div>

            <div class="form-control">
                <label class="label">
                    <span class="label-text">文档</span>
                </label>
                <Editor :value="form.doc" @input="form.doc = $event" :id="editorId" />
            </div>

            <div class="form-control">
                <label class="label">
                    <span class="label-text">当前价格</span>
                </label>
                <input type="number" v-model="form.price" placeholder="Price" class="input input-bordered" required />
            </div>

            <div class="form-control">
                <label class="label">
                    <span class="label-text">原价格</span>
                </label>
                <input type="number" v-model="form.old_price" placeholder="Old Price" class="input input-bordered" />
            </div>

            <div class="form-control">
                <label class="label">
                    <span class="label-text">限时降价</span>
                </label>
                <input type="date" v-model="form.discount_to" placeholder="Discount Time" class="input input-bordered" />
            </div>

            <!-- <div class="form-control">
                <label class="label">
                    <span class="label-text">体验地址</span>
                </label>
                <input type="text" v-model="form.url" placeholder="URL" class="input input-bordered" />
            </div> -->

            <div class="form-control mt-2 pt-4 mx-auto">
                <button type="submit" class="btn btn-primary text-white">提交审核</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import {
    callApi, getOptions, sendFile
} from '@/js/common';

import Editor from './tiny-editor.vue';
import mixins from '@/js/mixins';

export default {
    mixins: [mixins],
    props: ["data"],
    components: {
        Editor
    },
    data() {
        return {
            editorId: "doc",
            categories: [],
            videos: [],
            packages: [],
            form: [
                // {
                //     "category": "ProdIntro",
                //     "created_at": "2024-01-07 10:24:05",
                //     "desc": "专门展示公司产品",
                //     "doc": "<p>使用手册</p>\n<p><img src=\"a_xia_yun/upload/ae41b3d3a03e5a1e2c1dc4baeb8e120b\" alt=\"\" width=\"936\" height=\"468\"></p>",
                //     "id": 1,
                //     "img": "/a_xia_yun/upload/72b277db4d519592ca7fe336f68cb367",
                //     "old_price": 500,
                //     "package": null,
                //     "price": 300,
                //     "sell_count": 122,
                //     "star": 4.199999809265137,
                //     "status": 1,
                //     "status_label": {
                //         "int_value": 1,
                //         "label": "有效"
                //     },
                //     "title": "公司产品介绍页",
                //     "updated_at": "2024-01-25 20:28:23",
                //     "url": "https://www.bing.com",
                //     "user_id": 3,
                //     "video": {
                //         "category": "AppService",
                //         "id": 1,
                //         "status": 1,
                //         "title": "公司产品介绍",
                //         "video_url": "/a_xia_yun/upload/10028d973fcd84a969b0791bfdaad31f"
                //     },
                //     "video_id": 1
                // }
            ]
        };
    },
    mounted() {
        this.getTypeList()
        this.getVideoList()
        this.getPackageList()
        if (this.data) {
            this.form = this.data
            if (this.form.discount_to) {
                this.form.discount_to = this.form.discount_to.split(" ")[0]
            }
        } else {
            this.form = {

            }
        }

    },
    methods: {
        returnTo(index = 'my_app') {
            this.$emit("return", index)
        },
        triggerImageInput() {
            this.$refs.imageInput.click();
        },
        async getTypeList() {
            try {
                const res = await getOptions('/application_info/category')
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                } else {
                    this.categories = res.data.options
                }
            } catch (e) {
                console.error("get type list error", e)
            }
        },
        async getVideoList() {
            try {
                const res = await callApi("video_list")
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                }
                this.videos = res.data
            } catch (e) {
                console.error("get video list error", e)
            }
        },
        async getPackageList() {
            try {
                const res = await callApi("pkg_list")
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                }
                this.packages = res.data
            } catch (e) {
                console.error("get package list error", e)
            }
        },
        onSubmit() {
            // 表单提交逻辑
            console.log('Form submitted', this.form);
            if (!this.form.img) {
                alert("请上传封面")
                return
            }

            callApi("upsert_app", this.form, "upsert_app").then(res => {
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                }
                this.returnTo()
            })

        },
        async handleImageUpload(event) {
            // 处理图片上传逻辑
            const input = event.target;
            if (input.files && input.files[0]) {
                const file = input.files[0];
                const res = await sendFile(file)
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                }
                console.log("upload img", res.data)
                this.$set(this.form, "img", res.data.value)
            }
        },
    }
};
</script>