<template>
    <div>
        <div id="qs-header">
            <qs-header cur-menu="service"></qs-header>
        </div>
        <div class="max-w-7xl px-0 lg:px-8 flex flex-col mx-auto">
            <div id="service-content">
                <service-content></service-content>
            </div>
        </div>
    </div>
</template>


<script>
import QsHeader from '../qs-header.vue';
import ServiceContent from '../service-content.vue';

export default {
    components: {
        "qs-header": QsHeader,
        "service-content": ServiceContent,
    }
}
</script>