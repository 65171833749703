<template>
    <div>
        <div id="qs-header">
            <qs-header cur-menu="doc"></qs-header>
        </div>
        <div class="drawer lg:drawer-open">
            <input id="my-drawer" type="checkbox" class="drawer-toggle" />
            <div class="drawer-content">
                <div class=" lg:hidden fixed bottom-16 right-8 z-10">
                    <label for="my-drawer" class="btn shadow text-white btn-accent btn-circle">目录</label>
                </div>
                <div class="flex flex-row gap-2 items-start">
                    <div id="video-doc" class="flex-1 lg:m-4">
                        <video-doc class="shadow"></video-doc>
                    </div>
                </div>
            </div>
            <div class="drawer-side lg:h-auto">
                <label for="my-drawer" aria-label="close sidebar" class="drawer-overlay"></label>
                <div id="doc-tree" class="max-w-xs max-w-content lg:mt-4 lg:ml-4">
                    <doc-tree></doc-tree>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QsHeader from '../qs-header.vue';
import VideoDoc from '../video-doc.vue';
import DocTree from '../doc-tree.vue';


export default {
    components: {
        "qs-header": QsHeader,
        "video-doc": VideoDoc,
        "doc-tree": DocTree,
    }
}
</script>