<template>
    <div class="flex flex-col gap-4 mt-4">
        <div v-if="!order_list.length" class="text-center text-gray-500">暂无订单</div>
        <div v-for="item in order_list" :key="item.id"
            class="card bg-white shadow hover:shadow-lg transition-shadow duration-300 ease-in-out rounded-lg overflow-hidden">
            <div
                class="flex flex-col md:flex-row justify-between p-4 bg-gray-50 hover:bg-blue-50 transition-colors duration-300 gap-4 md:gap-16">
                <div class="flex flex-col gap-2">
                    <div class="flex gap-2 cursor-pointer" @click="click_order(item)">
                        <div class="text-xl font-semibold text-gray-800">
                            {{ item.title }}</div>
                    </div>
                    <div class="text-md text-gray-600">{{ item.desc }}</div>
                    <div class="flex flex-wrap gap-8 mt-2 items-center">
                        <div class="text-sm font-medium text-gray-700">价格: <span class="text-red-500">¥{{ item.price
                        }}</span></div>
                        <div v-if="item.payment_time" class="text-sm font-medium text-gray-700">购买时间: {{
                            formatRelativeTime(item.payment_time) }}
                        </div>
                        <div v-else class="text-sm font-medium text-gray-700">
                            状态: <span class="text-red-500">{{ item.status?.label }}</span>
                        </div>
                        <div v-if="item.payment_status == 'pay_successed'"
                            class="text-sm font-medium text-gray-700 flex items-center gap-2">
                            <span>评分:</span>
                            <div class="rating" @click.stop="() => { }">
                                <input v-for="i in 5" :key="i" type="radio" :name="`order-` + item.id"
                                    class="mask mask-star-2 bg-orange-400" :checked="get_star(item.star) == i" />
                            </div>
                        </div>
                        <div class="flex items-center gap-2 cursor-pointer hover:text-blue-500 transition-colors duration-300"
                            @click.stop="chat(item.user_id)">
                            💬
                            <span class="text-sm text-gray-700 hover:text-blue-500">联系买家</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    callApi, EventBus, sendFile
} from '@/js/common';

import { CHAT_TO_USER } from '@/js/const';
import mixins from '@/js/mixins';

export default {
    mixins: [mixins],
    data() {
        return {
            order_list:
                [
                    // {
                    //     "created_at": "2024-01-25 11:19:54",
                    //     "desc": "专门展示公司产品",
                    //     "id": 1,
                    //     "info": {
                    //         "category": "ProdIntro",
                    //         "desc": "专门展示公司产品",
                    //         "id": 1,
                    //         "package_id": 1,
                    //         "pkg": {
                    //             "id": 1,
                    //             "package_path": "/a_xia_yun/upload/unknown/70c8c42577f60e07f3d8979cf4b3240f"
                    //         },
                    //         "title": "公司产品介绍页",
                    //         "user_id": 3
                    //     },
                    //     "item_id": 1,
                    //     "item_key": "active",
                    //     "item_type": "AppService",
                    //     "order_number": "innerNo110",
                    //     "package_id": 1,
                    //     "payment_method": "wechat",
                    //     "payment_status": "pay_successed",
                    //     "payment_time": "2024-01-25 00:00:00",
                    //     "pkg_user_id": 3,
                    //     "platform_order_number": null,
                    //     "price": 233,
                    //     "recommend_id": null,
                    //     "refund_amount": 0,
                    //     "refund_time": null,
                    //     "star": 5,
                    //     "status": {
                    //         "label": "支付成功",
                    //         "value": "pay_successed"
                    //     },
                    //     "title": "公司产品介绍页",
                    //     "updated_at": "2024-01-25 22:45:15",
                    //     "user_id": 3
                    // }
                ]

        }

    },
    mounted() {
        this.get_order_list();
    },
    methods: {
        click_order(order) {
            console.log("click_order", order);
            if (order.item_type == "AppService") {
                this.$router.push({ name: "app-detail", params: { id: order.info.id } })
            } else if (order.item_type == "MicroService") {
                this.$router.push({ name: "service-detail", params: { id: order.info.id } })
            } else if (order.item_type == "TechService") {
                this.$router.push({ name: "support-detail", params: { id: order.info.id } })
            }
        },
        chat(user_id) {
            try {
                EventBus.$emit(CHAT_TO_USER, user_id);
            } catch (e) {
                console.log("error", e);
            }
        },
        get_star(rating) {
            // 取整数
            return Math.ceil(rating)
        },
        download_try(service) {
            console.log("download_try", service);
        },
        get_order_list() {
            callApi("get_sell_orders", {}, `get_sell_order_list`).then((res) => {
                if (res.status < 0) {
                    console.log("get_sell_order_list error", res.msg)
                    return;
                }

                this.order_list = res.data
            })
        }

    }
}
</script>