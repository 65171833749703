<template>
    <div class="bg-white shadow-md " v-if="comment">
        <!-- Main Comment -->
        <div class="flex space-x-4 p-4 border-b">
            <img class="w-12 h-12 rounded-full" :src="ImageUrl(comment.user?.avatar)" alt="User"
                onerror="this.onerror=null; this.src='/images/default_avatar.png'">
            <div class="flex-1 space-y-2">
                <div class="flex justify-between items-center">
                    <h3 class="font-semibold">{{ comment.user?.name }}</h3>
                    <span class="text-gray-500 text-xs">{{ formatRelativeTime(comment.created_at) }}</span>
                </div>
                <p class="text-gray-700 text-sm leading-6">{{ comment.content }}</p>
                <div
                    class="flex space-x-4 md:space-x-8 lg:space-x-16 text-gray-400 text-sm reply-tools items-center justify-between lg:justify-end">
                    <button class="flex items-center p-1 rounded hover:bg-gray-200 gap-1" @click="clickLike">
                        <svg class="w-5 h-5" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                            style="-webkit-print-color-adjust: exact;" fill="none" version="1.1">
                            <path rx="0" ry="0" :fill="like ? '#fc3737' : '#ff000000'" stroke="#fc3737" stroke-width="1.5"
                                d="M12.000,21.350L10.550,20.030C5.400,15.360,2.000,12.270,2.000,8.500C2.000,5.410,4.420,3.000,7.500,3.000C9.240,3.000,10.910,3.810,12.000,5.080C13.090,3.810,14.760,3.000,16.500,3.000C19.580,3.000,22.000,5.410,22.000,8.500C22.000,12.270,18.600,15.360,13.450,20.030L12.000,21.350ZZ" />
                        </svg>
                        <span class="likes-counter select-none">{{ comment.user_action?.like ? comment.user_action.like : ''
                        }}</span>
                    </button>
                    <button class="flex items-center p-1 rounded hover:bg-gray-200 gap-1" @click="clickDislike">
                        <svg class="w-5 h-5" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24" style="-webkit-print-color-adjust: exact;" fill="none" version="1.1">
                            <path rx="0" ry="0" :fill="dislike ? '#888' : '#ff000000'" stroke="#888" stroke-width="1.5"
                                d="M12.000,21.350L10.550,20.030C5.400,15.360,2.000,12.270,2.000,8.500C2.000,5.410,4.420,3.000,7.500,3.000C8.170,3.000,8.820,3.120,9.440,3.330L13.000,9.350L9.000,14.350L12.000,21.350ZL12.000,21.350ZM16.500,3.000C19.580,3.000,22.000,5.410,22.000,8.500C22.000,12.270,18.600,15.360,13.450,20.030L12.000,21.350L11.000,14.350L15.500,9.350L12.850,4.270C13.870,3.470,15.170,3.000,16.500,3.000ZZ" />

                        </svg>
                        <span class="dislikes-counter select-none">{{ comment.user_action?.dislike ?
                            comment.user_action.dislike : ''
                        }}</span>
                    </button>
                    <button @click="openReplyList = !openReplyList"
                        class="flex items-center p-1 rounded hover:bg-gray-200 reply-toggle gap-1">
                        💬
                        <span>{{ openReplyList ? "收起回复" : (comment.subcomment.length
                            > 0 ? comment.subcomment.length +
                        '条' : '') + "回复" }}</span>
                    </button>
                </div>
                <!-- Nested reply input (hidden initially) -->
                <comment-input :show="openReplyList" v-on:send-comment="addReply" placeholder="回复评论" />
            </div>
        </div>
        <!-- Nested Replies -->
        <transition name="slide-fade">
            <div v-if="openReplyList">
                <div v-for="(reply, index) in comment.subcomment" :key="index"
                    class="flex space-x-4 p-4 pl-14 border-b bg-gray-50">
                    <img class="w-10 h-10 rounded-full" :src="ImageUrl(reply.user?.avatar)" alt="Replier"
                        onerror="this.onerror=null; this.src='/images/default_avatar.png'">
                    <div class="flex-1 space-y-2">
                        <div class="flex justify-between items-center">
                            <h3 class="font-semibold text-sm">{{ reply.user?.name }}</h3>
                            <span class="text-gray-500 text-xs">{{ formatRelativeTime(reply.created_at) }}</span>
                        </div>
                        <p class="text-gray-700 text-sm">{{ reply.content }}</p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
  
<script>
import CommentInput from './CommentInput.vue';
import {
    getQueryParam, getLocalUserInfo, callApi, showLoginDialog, userActionInfo,
    EventBus,
    userActionCountAddOne, userActionCountSubOne
} from '@/js/common';
import mixins from '@/js/mixins';

export default {
    props: ["data"],
    components: {
        CommentInput: CommentInput,
    },
    mixins: [mixins],
    data() {
        return {
            openReplyList: false,
            like: false,
            dislike: false,
            comment: null,
            // {
            //     "content": "在这个示例中，AppendToFile函数尝试打开（或创建）一个文件，并以追加模式写入传递给它的数据。文件的权限被设置为0644，这意味着所有者有读写权限，而组和其他用户则有读权限。如果文件打开或写入发生错误，它会返回错误。\n\n\n请注意，这个示例中的AppendToFile函数接受字符串数据，但如果你要写入的是二进制数据或来自其他来源的数据，可能需要使用不同的写入方法，例如Write或WriteAt等。",
            //     "created_at": "2024-01-08 10:14:18",
            //     "dislikes": 1,
            //     "id": 1,
            //     "likes": 2,
            //     "parent_id": null,
            //     "subcomment": [
            //         // {
            //         //     "content": "支持一下这位兄弟",
            //         //     "created_at": "2024-01-08 10:17:19",
            //         //     "id": 2,
            //         //     "parent_id": 1,
            //         //     "user": {
            //         //         "avatar": "/a_xia_yun/upload/ab71d7a4aa2ddb880fd01d3ffc165dc3",
            //         //         "id": 2,
            //         //         "name": "官方"
            //         //     },
            //         //     "user_id": 2
            //         // }
            //     ],
            //     "target_id": 1,
            //     "user": {
            //         "avatar": "/a_xia_yun/upload/ab71d7a4aa2ddb880fd01d3ffc165dc3",
            //         "id": 2,
            //         "name": "官方"
            //     },
            //     "user_id": 2
            // }
        };
    },
    mounted() {
        if (this.data) {
            this.comment = this.data;
            //console.log("comment user_action:", JSON.stringify(this.comment.user_action));
        }

        // 取得自己点赞信息，如果已经登录了
        if (getLocalUserInfo()) {
            callApi(255, { target_id: this.comment.id }, "get_comment_likes").then(res => {
                if (res.data.length != 2) {
                    console.error("falied get_comment_likes: ", res.data)
                    return
                }
                this.like = res.data[0] ? true : false;
                this.dislike = res.data[1] ? true : false;
            })
        }
    },
    methods: {
        clickLike() {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }
            callApi(this.like ? 248 : 237, { target_id: this.comment.id }, "click_like").then(res => {
                if (res.status == 0) {
                    if (!this.like) {
                        userActionCountAddOne(this.comment.user_action, "like")

                        if (this.dislike) {
                            this.dislike = false
                            userActionCountSubOne(this.comment.user_action, "dislike")
                        }

                    } else {
                        userActionCountSubOne(this.comment.user_action, "like")
                    }
                    this.like = !this.like
                }
            })
        },

        clickDislike() {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }
            callApi(this.dislike ? 249 : 238, { target_id: this.comment.id }, "click_dislike").then(res => {
                if (res.status == 0) {
                    if (!this.dislike) {
                        userActionCountAddOne(this.comment.user_action, "dislike")
                        if (this.like) {
                            this.like = false
                            userActionCountSubOne(this.comment.user_action, "like")
                        }
                    } else {
                        userActionCountSubOne(this.comment.user_action, "dislike")
                    }

                    this.dislike = !this.dislike
                }
            })
        },
        addReply(comment, success) {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }

            if (!comment) {
                return
            }
            callApi(253, { target_id: this.comment.target_id, comment_type: this.comment.comment_type, content: comment, parent_id: this.comment.id }, "add_reply").then(res => {
                if (res.status == 0) {
                    this.comment.subcomment.push(res.data.comment)
                    success()
                } else {
                    console.log(res)
                    alert(res.msg)
                }
            })

            this.showReplyInput = false;
        }
    },
};
//cubic-bezier(1.0, 0.5, 0.8, 1.0)
</script>


<style scoped>
.v-enter-from,
/* vue3*/
.v-leave-to {
    opacity: 0;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s;
}

.slide-fade-enter-active {
    transition: all .2s ease;
}

.slide-fade-leave-active {
    transition: all .2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for below version 2.1.8 */
    {
    transform: translateY(-10px);
    opacity: 0;
}
</style>