<template>
    <div class="w-full flex flex-col items-center justify-center rounded" v-if="appInfo">

        <div class="lg:px-12 w-full flex flex-col lg:flex-row items-none justify-center gap-2 lg:gap-8 mt-2 lg:mt-8">
            <div class="bg-black max-w-7xl w-full">
                <video :src="FileUrl(appInfo.video?.video_url)"
                    style="aspect-ratio: 16/9; width: 100%; height: 100%; object-fit: contain;" autoplay controls></video>
            </div>
            <div
                class="px-4 py-4 lg:px-0 lg:w-96 lg:py-4 flex flex-row gap-3 flex-wrap lg:flex-col justify-between items-center lg:items-start">

                <div class="text-gray-900 font-semibold">团队: {{ appInfo.team_name }}</div>
                <div class="text-gray-600 text-sm"> {{ appInfo.skill_desc }}</div>
                <div class="text-gray-900">开单: <span class="text-green-500 font-semibold">{{ appInfo.order_count }} 份</span>
                </div>
                <div class="text-gray-900">评分: <span class="text-yellow-400 font-semibold">{{ appInfo.star.toFixed(1) }}
                        ⭐️</span></div>


                <div class="flex flex-col gap-8">
                    <div class="flex flex-row gap-2 cursor-pointer hover:text-blue-400 "
                        @click.stop="chat(appInfo.user_id)">
                        <div
                            class="flex items-center gap-2 cursor-pointer hover:text-blue-500 transition-colors duration-300">
                            💬
                            <span class="text-sm text-gray-700 hover:text-blue-500">联系</span>
                        </div>
                    </div>
                </div>
                <div @click="placeOrder()" v-if="!orderInfo || orderInfo.status === 1"
                    class="bg-blue-500 hover:bg-blue-600 text-white font-semibold text-lg py-2 px-4 rounded-full shadow-lg hover:shadow-md transition duration-300 ease-in-out cursor-pointer flex items-center justify-center">
                    购买技术服务 <span class="text-sm ml-2">¥{{ appInfo.price }}/小时</span>
                </div>
                <div v-else
                    class="bg-blue-500 hover:bg-blue-600 text-white font-semibold text-lg py-2 px-4 rounded-full shadow-lg hover:shadow-md transition duration-300 ease-in-out cursor-pointer flex items-center justify-center">
                    <a v-if="orderInfo.payment_status === 'pay_successed' && orderInfo.status === 0">
                        服务正在进行
                    </a>
                    <a v-else @click="$router.push({ name: 'user-center', query: { menu: 'my_orders' } })">
                        支付服务订单
                    </a>
                </div>
            </div>
        </div>

        <div class="flex flex-row flex-wrap gap-10 lg:gap-20 text-sm justify-between px-2 
            lg:justify-center items-center mt-4 lg:mt-8 w-full md:max-w-3xl lg:max-w-7xl">

        </div>


        <div class="w-full py-2 lg:py-6 max-w-7xl mb-12 ">

            <div class="flex flex-row w-full">
                <div class="border-b w-4"></div>
                <div role="tablist" class="tabs tabs-lifted">
                    <a role="tab" class="tab" :class="{ 'tab-active': curTab === 'skill_desc' }"
                        @click="curTab = 'skill_desc'">团队介绍</a>
                    <a role="tab" class="tab" :class="{ 'tab-active': curTab === 'comment' }" @click="curTab = 'comment'">{{
                        '评论('
                        + appInfo.comments?.length + ')' }}</a>
                    <a role="tab" class="tab" :class="{ 'tab-active': curTab === 'cases' }"
                        @click="curTab = 'cases'">成功案例</a>
                </div>
                <div class="border-b flex-1 flex justify-end">
                    <button class=" text-sm text-blue-500 mr-4" v-if="curTab === 'comment'"
                        @click="showCommentInput = !showCommentInput">发表评论</button>
                </div>
            </div>


            <div class="mt-6" :class="{ hidden: curTab != 'comment' }">
                <comment-input placeholder="发表评论" @send-comment="sendComment" :show="showCommentInput"></comment-input>
                <div v-for="comment in appInfo.comments" :key="comment.id">
                    <comment-thread :data="comment"></comment-thread>
                </div>
                <div v-if="appInfo.comments?.length === 0" class="mt-4 text-gray-500 p-2">
                    暂无评论
                </div>
            </div>
            <div class="mt-6 text-gray-500 leading-8 p-2 lg:p-0" :class="{ hidden: curTab != 'skill_desc' }">
                <div>{{ appInfo.skill_desc }}</div>
            </div>
            <div class="mt-6  doc-content mce-content-body" :class="{ hidden: curTab != 'cases' }">
                <div v-html="updateRichTextPaths(appInfo.cases)"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Prism from "prismjs";
import "prismjs/themes/prism.css"; // you can change

import CommentThread from './CommentThread.vue';
import CommentInput from './CommentInput.vue';
import VideoFav from './video-fav.vue';

import {
    getQueryParam, getLocalUserInfo, callApi, showLoginDialog, userActionInfo,
    EventBus, payOrder,
    userActionCountAddOne, userActionCountSubOne
} from '@/js/common';
import { CHAT_TO_USER, SHOW_QRCODE_DIALOG, CLOSE_QRCODE_DIALOG, } from '@/js/const';
import mixins from '@/js/mixins';

export default {
    mixins: [mixins],
    data() {
        return {
            curTab: "cases",
            showCommentInput: false,
            orderInfo: null, // 订单信息
            appInfo: null
            // {
            //     "category": "TPM",
            //     "comments": [],
            //     "created_at": "2024-01-11 12:17:06",
            //     "id": 1,
            //     "order_count": 203,
            //     "price": 300,
            //     "skill_desc": "以问题为导向，通过成熟的技术，解决问题。形成解决现有问题，可迭代解决未知问题的系统给客户。",
            //     "star": 4.900000095367432,
            //     "status": 1,
            //     "team_name": "官方项目整包",
            //     "user": {
            //         "avatar": "/a_xia_yun/upload/ab71d7a4aa2ddb880fd01d3ffc165dc3",
            //         "id": 2,
            //         "name": "官方"
            //     },
            //     "user_action": [],
            //     "user_id": 2,
            //     "video": {
            //         "id": 1,
            //         "title": "公司产品介绍",
            //         "video_url": "/a_xia_yun/upload/10028d973fcd84a969b0791bfdaad31f"
            //     },
            //     "video_id": 1
            // }
        }
    },

    mounted() {
        window.Prism = window.Prism || {};
        window.Prism.manual = true;
        Prism.highlightAll(); // highlight your code on mount
        
        // 取得地址上应用id
        let id = this.$route.params.id;
        if (!id) {
            console.log("id not exist")
            return;
        }

        // 取得 ms 内容
        callApi(259, { id: id }, `get_support_info`).then((res) => {
            this.appInfo = res.data
            this.appInfo.user_action = userActionInfo(this.appInfo.user_action)
            this.appInfo.comments.map(item => {
                item.user_action = userActionInfo(item.user_action)
            })

            this.setSiteInfo(this.appInfo.team_name, this.appInfo.skill_desc)
            // 取得自己点赞信息，如果已经登录了
            if (getLocalUserInfo()) {
                callApi(260, { target_id: this.appInfo.id }, "get_support_info").then(res => {
                    if (res.data.length != 2) {
                        console.error("falied get_support_info: ", res.data)
                        return
                    }
                    this.like = res.data[0] ? true : false;
                    this.fav = res.data[1] ? true : false;
                })

                // 看自己是否下过单，如果下过单了就显示下单按钮
                this.haveOrdered()
            }
        })

    },
    methods: {
        clickLike() {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }
            callApi(this.like ? 246 : 236, { target_id: this.appInfo.id }, "click_like").then(res => {
                if (res.status == 0) {
                    if (!this.like) {
                        userActionCountAddOne(this.appInfo.user_action, "like")
                    } else {
                        userActionCountSubOne(this.appInfo.user_action, "like")
                    }
                    this.like = !this.like
                }
            })
        },
        haveOrdered() {
            callApi("have_order", { item_id: this.appInfo.id, item_type: "AppService" }, "").then(res => {
                if (res.status < 0) {
                    console.error("have_order error: ", res.data)
                    return
                }
                this.orderInfo = res.data
            })
        },
        clickFav() {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }
            callApi(this.fav ? 245 : 235, { target_id: this.appInfo.id }, "click_fav").then(res => {
                if (res.status == 0) {
                    if (!this.fav) {
                        userActionCountAddOne(this.appInfo.user_action, "favorite")
                    } else {
                        userActionCountSubOne(this.appInfo.user_action, "favorite")
                    }
                    this.fav = !this.fav
                }
            })
        },
        chat(user_id) {
            try {
                EventBus.$emit(CHAT_TO_USER, user_id);
            } catch (e) {
                console.log("error", e);
            }
        },
        placeOrder() {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }

            callApi("place_order", {
                item_id: this.appInfo.id,
                item_type: "MicroService",
                recommend_id: this.getRecommandId()
            }).then(res => {
                if (res.status < 0) {
                    alert(res.msg)
                } else {
                    if (res.data.payment_status === "wait_pay") {
                        const pay_over = () => {
                            EventBus.$emit(CLOSE_QRCODE_DIALOG)
                            this.haveOrdered()
                        };
                        payOrder(res.data.order_number, pay_over).then(no => {
                            if (no) {
                                // 设置付款二维码
                                EventBus.$emit(SHOW_QRCODE_DIALOG, no, res.data.price, pay_over)
                            }
                        })
                    } else {
                        this.haveOrdered()
                    }
                    //this.$router.push({ name: 'user-center', params: { menu: 'my_orders' } })
                }
            })
        },
        sendComment(comment, success) {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }

            if (!comment) return;

            callApi(252, { target_id: this.appInfo.id, comment_type: "TechService", content: comment }, "send_comment").then(res => {
                if (res.status == 0) {
                    this.showCommentInput = false;
                    let comment = res.data.comment;
                    // 插入到最前面
                    comment.user_action = userActionInfo(comment.user_action)
                    this.appInfo.comments.unshift(res.data.comment)
                    success();
                } else {
                    console.log(res)
                    alert(res.msg)
                }
            })
        }
    },
    components: {
        "comment-thread": CommentThread,
        "comment-input": CommentInput,
        // "video-fav": VideoFav,
    }
}
</script>

<!-- <style scoped src="../assets/codepen.min.css"></style> -->