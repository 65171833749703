<template>
    <div class="flex flex-col lg:flex-row lg:gap-16">
        <!-- pc 导航 -->
        <div class="hidden lg:block rounded-lg w-64 h-80">
            <div class="h-16 min-h-16 lg:h-0 lg:min-h-0"></div>
            <ul class="menu bg-base-400 w-56 lg:rounded-box shadow" style="z-index:-10;">
                <li v-for="item in naviMenu" :key="item.id" class="h-12 text-base">
                    <a v-if="!item.children || !item.children.length" :class="{ 'text-blue-500': item.id == selectedId }"
                        @click="selectItem(item.id)">{{ item.title }}</a>
                </li>
            </ul>
        </div>
        <!-- 手机导航 -->
        <div class="lg:hidden">
            <div class="flex flex-row justify-between gap-8 flex-wrap gap-y-4 mb-4">
                <span class="cursor-pointer text-sm" v-for="item in naviMenu" :key="item.id"
                    :class="{ 'text-blue-400': item.id === selectedId }" @click="selectItem(item.id)">{{ item.title
                    }}</span>
            </div>
        </div>

        <!-- 个人信息 -->
        <user-info v-if="selectedId == 'user_info'" class="flex-1"></user-info>

        <!-- 我的订单 -->
        <div v-if="selectedId == 'my_orders'" class="flex-1">
            <my-orders></my-orders>
        </div>
        <!-- 我的收藏 -->
        <div v-if="selectedId == 'my_favorite'" class="flex-1">
            <my-favorite></my-favorite>
        </div>

        <!-- 我的应用 -->
        <div v-if="selectedId == 'my_app'" class="flex flex-col gap-6 flex-1 lg:ml-6">
            <app-elm :user_center="true" @edit-data="editData($event, 'app_upload')"></app-elm>
            <div class="flex justify-end">
                <button class="btn" @click="selectItem('app_upload')">出售应用</button>
            </div>
        </div>
        <!-- 我的微服务 -->
        <div v-if="selectedId == 'my_microservice'" class="flex flex-col gap-6 flex-1">
            <service-elm :user_center="true" @edit-data="editData($event, 'ms_upload')"></service-elm>
            <div class="flex justify-end">
                <button class="btn " @click="selectItem('ms_upload')">出售微服务</button>
            </div>
        </div>
        <!-- 我的支持 -->
        <div v-if="selectedId == 'my_support'" class="flex flex-col gap-6 flex-1">
            <support-elm :user_center="true" @edit-data="editData($event, 'tech_service')"></support-elm>
            <div class="flex justify-end">
                <button class="btn " @click="selectItem('tech_service')">添加服务团队</button>
            </div>
        </div>

        <!-- 上传应用 -->
        <div v-if="selectedId == 'app_upload'" class="flex-1 flex flex-col items-center">
            <app-upload :data="currentData" @return="(index) => selectItem(index)"></app-upload>
        </div>

        <!-- 上传微服务 -->
        <div v-if="selectedId == 'ms_upload'" class="flex-1 flex flex-col items-center">
            <ms-upload :data="currentData" @return="(index) => selectItem(index)"></ms-upload>
        </div>

        <!-- 成为服务团队 -->
        <div v-if="selectedId == 'tech_service'" class="flex-1 flex flex-col items-center">
            <tech-service :data="currentData" @return="(index) => selectItem(index)"></tech-service>
        </div>

        <!-- 我的销售订单 -->
        <div v-if="selectedId == 'my_sell'" class="flex-1">
            <sell-orders></sell-orders>
        </div>

        <!-- 我的推广销售订单 -->
        <div v-if="selectedId == 'my_recommand'" class="flex-1">
            <recommand-orders></recommand-orders>
        </div>

        <!-- 我的提现记录 -->
        <div v-if="selectedId == 'my_withdraw'" class="flex-1">
            <withdraw-list></withdraw-list>
        </div>

        <!-- 我的投稿 -->
        <div v-if="selectedId == 'my_doc'" class="flex-1">
            <my-doc></my-doc>
        </div>

        <!-- 我的安装包 -->
        <div v-if="selectedId == 'my_package'" class="flex-1">
            <my-package></my-package>
        </div>

        <!-- 我的视频 -->
        <div v-if="selectedId == 'my_video'" class="flex-1">
            <my-video></my-video>
        </div>
    </div>
</template>


<script>

import {
    getLocalUserInfo,
    EventBus
} from '@/js/common'
import { USER_LOGOUT } from '@/js/const';

import UserInfo from '@/components/user/user-info.vue'
import MyOrders from '@/components/user/my-orders.vue'
import MyFavorite from '@/components/user/my-favorite.vue'
import MyDoc from '@/components/user/my-doc.vue'
import MyPackage from '@/components/user/package-mgr.vue'
import MyVideo from '@/components/user/video-mgr.vue'
import AppElm from '@/components/app-elm.vue'
import ServiceElm from '@/components/service-elm.vue'
import SupportElm from '@/components/support-elm.vue'
import AppUpload from '@/components/user/app-upload.vue'
import MsUpload from '@/components/user/ms-upload.vue'
import TechService from '@/components/user/tech-service.vue'
import SellOrders from '@/components/user/sell-orders.vue'
import RecommandOrders from '@/components/user/recommand-orders.vue'
import WithdrawList from '@/components/user/withdraw-list.vue'

export default {

    data() {
        return {
            userInfo: getLocalUserInfo(),
            selectedId: 'user_info',
            currentData: { // 需要编辑的项

            },
            naviMenu: [
                {
                    id: "user_info",
                    title: "个人信息",
                },
                {
                    id: "my_orders",
                    title: "我的订单"
                },
                {
                    id: "my_favorite",
                    title: "我的收藏"
                },
                {
                    id: "my_recommand",
                    title: "推广销售记录",
                },
                {
                    id: "my_withdraw",
                    title: "我的提现记录",
                },
                {
                    id: "my_doc",
                    title: "我的投稿",
                },
                {
                    id: "my_package",
                    title: "安装包管理",
                },
                {
                    id: "my_video",
                    title: "视频管理",
                },
                {
                    id: "my_app",
                    title: "我的应用",
                },
                {
                    id: "my_microservice",
                    title: "我的微服务",
                },
                {
                    id: "my_support",
                    title: "技术服务",
                },
                {
                    id: "my_sell",
                    title: "产品销售记录",
                },
            ]
        };
    },
    created() {
        EventBus.$on(USER_LOGOUT, this.onUserLogout)
        window.addEventListener('popstate', this.handlePopState);
    },
    beforeDestroy() {
        EventBus.$off(USER_LOGOUT, this.onUserLogout)
        window.removeEventListener('popstate', this.handlePopState);
    },
    mounted() {
        let menu = this.$route.params.menu
        if (!menu) {
            menu = 'user-info'
        }
        this.selectedId = menu
       
    },
    methods: {
        handlePopState() {
            let menu = this.$route.params.menu
            if (!menu) {
                menu = 'user-info'
            }      
            this.selectedId = menu
        },
        selectItem(id) {

            console.log("selectItem", id);
            this.currentData = null;
            if (!id) {
                this.selectedId = ''
            }
            this.selectedId = id;

            this.$router.replace({
                name: 'user-center',
                params: {
                    menu: id
                }
            })
        },
        editData(data, page) {
            this.selectItem(page, data)
            this.currentData = data
        },
        onUserLogout() {
            this.$router.push({
                name: 'app'
            })
        },
    },
    components: {
        "user-info": UserInfo,
        "app-elm": AppElm,
        "service-elm": ServiceElm,
        "support-elm": SupportElm,
        "app-upload": AppUpload,
        "my-orders": MyOrders,
        "sell-orders": SellOrders,
        "withdraw-list": WithdrawList,
        "recommand-orders": RecommandOrders,
        "my-favorite": MyFavorite,
        "my-package": MyPackage,
        "my-doc": MyDoc,
        "my-video": MyVideo,
        "ms-upload": MsUpload,
        "tech-service": TechService,
    }
}
</script>