<template>
    <div class="container mx-auto p-6 bg-white">

        <div class="flex flex-col-reverse md:flex-row items-start justify-between max-w-3xl gap-8 md:gap-4">
            <div class="flex flex-col gap-16 flex-1">
                <div class="flex flex-col lg:flex-row flex-wrap gap-8 items-start lg:items-center justify-between">
                    <!-- 余额 -->
                    <div class="text-sm text-gray-400 flex flex-row gap-4 items-center">
                        <div>收入: <span class="text-red-500"> {{ userInfo.income || 0 }}</span>元</div>
                        <div><button class="btn btn-sm bg-transparent border-none" @click="withdraw()">提现</button></div>
                    </div>
                    <!-- 已提现 -->
                    <div class="text-sm text-gray-400">已提现: {{ userInfo.withdraw?.amount_sum.toFixed(2) || 0 }}元</div>
                    <!-- 提现审核中 -->
                    <div class="text-sm text-gray-400">提现中: {{ userInfo.wait_withdraw?.amount_sum.toFixed(2) || 0 }}元</div>
                    <!-- 手机号 -->
                    <div class="text-sm text-gray-400">手机号: {{ userInfo.mobile }}</div>
                    <!-- 注册时间 -->
                    <div class="text-sm text-gray-400">注册时间: {{ formatRelativeTime(userInfo.register_at) }}</div>
                    <!-- 提现支付宝账号 -->
                    <div class="text-sm text-gray-400">提现支付宝账号:
                        <span v-if="userInfo.payee_account && !isEditingPayeeAccount" @click="isEditingPayeeAccount = true">
                            {{ userInfo.payee_account }}</span>
                        <span v-else>
                            <input type="text" v-model="userInfo.payee_account"
                                class="p-2 border border-gray-300 rounded input input-sm w-32" @blur="updatePayeeAccount()"
                                @keyup.enter="updatePayeeAccount()" ref="payeeAccountInput" />
                        </span>
                    </div>
                </div>
                <!-- 修改密码 -->
                <div>
                    <button class="hidden btn btn-secondary btn-sm shadow"
                        @click="change_password.showModal()">修改密码</button>
                </div>
            </div>
            <div class="flex flex-col items-center justify-center w-full md:w-auto md:ml-32 pb-4 gap-8">
                <div @click="triggerFileInput" class="cursor-pointer">
                    <img :src="userInfo.avatar ? ImageUrl(userInfo.avatar) : '/images/default_avatar.png'"
                        class="w-24 h-24 rounded-full border-2 border-gray-300" />
                    <input type="file" class="hidden" ref="fileInput" @change="previewImage" />
                </div>
                <div @click="enableNameEdit" v-if="!isEditingName" class="mt-1  font-semibold cursor-pointer"
                    :class="{ 'text-gray-300': !userInfo.name }">
                    {{ userInfo.name || '用户名' }}
                </div>
                <div class="mt-2" v-else>
                    <input type="text" v-model="userInfo.name"
                        class="p-2 border border-gray-300 rounded input input-sm w-24" @blur="updateName()" ref="nameInput"
                        @keyup.enter="updateName()">
                </div>
            </div>
        </div>

        <!-- 奖励计划 -->
        <div class="text-sm text-gray-600 font-bold mt-9">推荐奖励:
            <span class="text-red-500">分享产品链接，用户购买后，即可获得 5% 的奖励, 内容页中的分享按钮也会带上推荐id</span>
            <button class="btn btn-sm bg-blue-500 text-white hover:bg-blue-600 ml-4"
                @click.stop="copyLink()">复制分享链接</button>
        </div>
        <!-- 提示信息 -->
        <div ref="alert" role="alert" class="alert my-8 hidden shadow-lg" @click="alertMsg = ''">
            <span class="text-white">{{ alertMsg }}</span>
        </div>
        <dialog class=" modal" id="change_password">
            <form method="dialog" class="modal-backdrop">
                <button>close</button>
            </form>
            <div class="max-w-sm w-full space-y-8 bg-white p-4 rounded-lg modal-box">
                <div>
                    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        修改密码
                    </h2>
                </div>
                <form class="mt-8 space-y-6" action="#" method="POST">
                    <input type="hidden" name="remember" value="true">
                    <div class="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label for="current-password" class="sr-only">当前密码</label>
                            <input v-model="passwordInfo.currentPassword" type="password" autocomplete="current-password"
                                required
                                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="当前密码">
                        </div>
                        <div>
                            <label for="new-password" class="sr-only">新密码</label>
                            <input v-model="passwordInfo.newPassword" type="password" autocomplete="new-password" required
                                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="新密码">
                        </div>
                        <div>
                            <label for="confirm-password" class="sr-only">确认新密码</label>
                            <input v-model="passwordInfo.confirmPassword" type="password" autocomplete="new-password"
                                required
                                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="确认新密码">
                        </div>
                    </div>

                    <div>
                        <button type="submit"
                            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            确认修改
                        </button>
                    </div>
                </form>
            </div>
        </dialog>
    </div>
</template>

<script>
import {
    callApi, sendFile, showLoginDialog
} from '@/js/common';
import mixins from '@/js/mixins';

export default {
    mixins: [mixins],
    data() {
        return {
            alertMsg: '', // 报错信息
            userInfo: {
                name: '',
                avatar: ''
            },
            passwordInfo: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            isEditingName: false,
            isEditingPayeeAccount: false
        }
    },
    mounted: function () {
        this.getUserInfo();
    },
    methods: {
        setAlertMsg(msg, is_error = true) {
            this.alertMsg = msg
            this.$refs.alert.classList.remove('alert-error');
            this.$refs.alert.classList.remove('alert-success');
            this.$refs.alert.classList.remove('hidden');

            if (is_error) {
                this.$refs.alert.classList.add('alert-error');
            } else {
                this.$refs.alert.classList.add('alert-success');
            }
            console.log('classList', this.$refs.alert.classList)
            setTimeout(() => {
                this.alertMsg = '';
                this.$refs.alert.classList.add('hidden');
            }, 2000);
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        enableNameEdit() {
            this.isEditingName = true;
            // Next tick is used to wait for the input to be rendered
            this.$nextTick(() => {
                this.$refs.nameInput.focus();
            });
        },
        previewImage(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.userInfo.avatar = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);

                // 上传头像
                this.updateAvatar(input.files[0]);
            }
        },
        updateAvatar(image) {
            console.log('Info update function goes here');
            sendFile(image).then(res => {
                if (res.status != 0) {
                    this.setAlertMsg(res.msg);
                    return;
                } else {
                    const avatar = res.data.value;
                    callApi("update_avatar", { avatar: res.data.value }, "update_avatar").then((res) => {
                        if (res.status != 0) {
                            this.setAlertMsg(res.msg);
                            return;
                        }
                        this.userInfo.avatar = avatar;
                    })
                }
            })
        },
        updateName() {
            this.isEditingName = false;

            callApi("exists_name", { name: this.userInfo.name }, "update_name").then((res) => {
                if (res.status != 0) {
                    this.setAlertMsg(res.msg);
                    return;
                }

                if (res.data.count > 0) {
                    this.setAlertMsg("用户名已存在");
                    return
                }

                callApi("update_name", { name: this.userInfo.name }, "update_name").then((res) => {
                    if (res.status != 0) {
                        this.setAlertMsg(res.msg);
                        return;
                    }
                })
            })
        },
        updatePayeeAccount() {
            this.isEditingPayeeAccount = false;
            callApi("update_payee_account", { payee_account: this.userInfo.payee_account }, "update_payee_account").then((res) => {
                if (res.status != 0) {
                    this.setAlertMsg(res.msg);
                    return;
                }
            })
        },
        updatePassword() {
            console.log('Password update function goes here');
            // 实际更新代码需要您使用例如 axios 发送至后端处理。
        },
        getUserInfo() {
            callApi("self_info", {}, "get_user_info").then((res) => {
                this.userInfo = res.data
            })
        },
        async withdraw() {
            const res = await callApi("withdraw", {}, "withdraw");
            if (res.status != 0) {
                this.setAlertMsg(res.msg);
                return;
            } else {
                this.userInfo.income = 0;
                this.setAlertMsg("提现申请已提交, 请在提现记录中查看", false);
            }
        },
        async copyLink() {
            try {
                let link = window.location.href
                // 只取域名，不需要路径
                link = link.split("/")[0] + "//" + link.split("/")[2]
                // 增加一个路径
                link = link + "/app.html"

                if (link.includes("?")) {
                    link = link + "&rmd_id=" + this.userInfo.id
                } else {
                    link = link + "?rmd_id=" + this.userInfo.id
                }
                await navigator.clipboard.writeText(link);
                this.setAlertMsg('链接已复制到剪贴板', false);
            } catch (err) {
                this.setAlertMsg('链接复制失败', true);
            }
        }
    }
}
</script>

<style scoped>
/* Scoped style ensures this style will only apply to this component */
.text-xl {
    min-width: 20rem;
    /* 提供足够的点击区域 */
}
</style>