<template>
    <div class="flex flex-row gap-8 flex-wrap gap-y-4 mb-4">
        <span class="cursor-pointer text-sm" :class="{ 'text-blue-400': !selectedId }"
            @click="selectItem({ id: '' })">全部</span>
        <span class="cursor-pointer text-sm" v-for="item in type_list" :key="item.value"
            :class="{ 'text-blue-400': item.value === selectedId }" @click="selectItem(item)">{{ item.label }}</span>
    </div>
</template>

<script>

import { callApi, EventBus,  } from '@/js/common'
import {SELECTE_APP_TYPE, SELECTE_MICROSERVICE_TYPE, SELECTE_SUPPORT_TYPE} from '@/js/const'

export default {
    props: {
        code: String
    },
    data() {
        return {
            selectedId: '',
            type_list: [
                // {
                //     value: 1,
                //     label: '门户',
                // },
                // {
                //     value: 2,
                //     label: '新闻',
                // },
                // {
                //     value: 3,
                //     label: 'CMS'
                // },
                // {
                //     value: 4,
                //     label: 'ERP',
                // },
                // {
                //     value: 5,
                //     label: 'CRM',
                // }
            ]
        }
    },
    mounted() {
        callApi(199, { code: this.code }, `get_search_list`).then((res) => {
            this.type_list = res.data
        })
    },
    methods: {
        selectItem(item) {
            console.log("selectItem", item);
            if (!item) {
                this.selectedId = ''
            }
            this.selectedId = item.value;
            EventBus.$emit(SELECTE_APP_TYPE, item.value);
            EventBus.$emit(SELECTE_MICROSERVICE_TYPE, item.value);
            EventBus.$emit(SELECTE_SUPPORT_TYPE, item.value);
        }
    }
}
</script>