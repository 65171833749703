<template>
    <div>
        <div ref="qrcode" style="width: 200px; height: 200px;"></div>
        
    </div>
</template>
  
<script>
import QRCode from 'qrcodejs2';

export default {
    props: {
        text: {
            type: String,
            default: ''
        }
    },
    methods: {
        generateQRCode() {
            if (this.text) {
                new QRCode(this.$refs.qrcode, {
                    text: this.text, // 使用data中绑定的textToEncode作为二维码内容
                    width: 200,
                    height: 200,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H
                });
            }
        }
    },
    mounted() {
        // 如果需要在组件挂载时立即生成二维码，可以在这里调用generateQRCode方法        
        this.generateQRCode();
    }
};
</script>