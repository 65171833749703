import { render, staticRenderFns } from "./CommentThread.vue?vue&type=template&id=7b24c7bb&scoped=true"
import script from "./CommentThread.vue?vue&type=script&lang=js"
export * from "./CommentThread.vue?vue&type=script&lang=js"
import style0 from "./CommentThread.vue?vue&type=style&index=0&id=7b24c7bb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_css-loader@6.9.1_vue-template-compiler@2.7.14_webpack@5.90.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b24c7bb",
  null
  
)

export default component.exports