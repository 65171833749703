<template>
    <div class="w-full flex flex-col items-center justify-center rounded lg:mt-8" v-if="appInfo">
        <video-fav :title="appInfo.title" :video_url="FileUrl(appInfo.video?.video_url)"
            :shares="appInfo.user_action?.share" :favorites="appInfo.user_action?.favorite"
            :likes="appInfo.user_action?.like" :fav="fav" :like="like" @click-share="copyCurrentLink()"
            @click-like="clickLike()" @click-fav="clickFav()"></video-fav>

        <div class="flex flex-row flex-wrap gap-6 text-sm my-2 justify-between px-2 w-full items-center">
            <div class="text-xl font-bold max-w-sm truncate hidden lg:inline mt-8 truncate">{{ appInfo.title }}
            </div>
            <div class="flex flex-row flex-wrap gap-10 gap-y-5 lg:gap-20 text-sm mt-2 justify-between px-2 
            lg:justify-center items-center mt-4 lg:mt-8 md:max-w-3xl lg:max-w-7xl">
                <div class="text-gray-900 font-semibold">作者: {{ appInfo?.user?.name }}</div>
                <div class="text-gray-900">已售: <span class="text-green-500 font-semibold">{{ appInfo.sell_count }} 份</span>
                </div>
                <div class="text-gray-900">评分: <span class="text-yellow-400 font-semibold">{{ appInfo.star?.toFixed(1) }}
                        ⭐️</span></div>

                <div class="flex flex-row gap-8 lg:gap-10 items-center">
                    <div class="flex flex-col gap-8">
                        <div class="flex flex-row gap-2 cursor-pointer hover:text-blue-400 ">
                            <div class="flex items-center gap-2 cursor-pointer hover:text-blue-500 transition-colors duration-300"
                                @click.stop="chat(appInfo?.user?.id)">
                                💬
                                <span class="text-sm text-gray-700 hover:text-blue-500">联系</span>
                            </div>
                        </div>
                    </div>
                    <div @click="placeOrder()" v-if="!orderInfo"
                        class="bg-blue-500 hover:bg-blue-600 text-white font-semibold text-lg py-2 px-4 rounded-full shadow-lg hover:shadow-md transition duration-300 ease-in-out cursor-pointer flex items-center justify-center">
                        购买微服务 <span class="text-sm ml-2">¥ {{ appInfo.price }}</span>
                    </div>
                    <div v-else
                        class="bg-blue-500 hover:bg-blue-600 text-white font-semibold text-lg py-2 px-4 rounded-full shadow-lg hover:shadow-md transition duration-300 ease-in-out cursor-pointer flex items-center justify-center">
                        <a v-if="orderInfo.payment_status === 'pay_successed'" :href="FileUrl(appInfo.pkg?.package_path)"
                            target="_blank">
                            下载微服务
                        </a>
                        <a v-else @click="$router.push({ name: 'user-center', query: { menu: 'my_orders' } })">
                            支付订单
                        </a>
                    </div>
                </div>
            </div>
        </div>


        <div class="w-full py-2 lg:py-6 max-w-7xl mb-12 ">

            <div class="flex flex-row w-full">
                <div class="border-b w-4"></div>
                <div role="tablist" class="tabs tabs-lifted">
                    <a role="tab" class="tab" :class="{ 'tab-active': curTab === 'desc' }" @click="curTab = 'desc'">功能描述</a>
                    <a role="tab" class="tab" :class="{ 'tab-active': curTab === 'comment' }" @click="curTab = 'comment'">{{
                        '评论('
                        + appInfo.comments?.length + ')' }}</a>
                    <a role="tab" class="tab" :class="{ 'tab-active': curTab === 'doc' }" @click="curTab = 'doc'">文档</a>
                </div>
                <div class="border-b flex-1 flex justify-end">
                    <button class=" text-sm text-blue-500 mr-4" v-if="curTab === 'comment'"
                        @click="showCommentInput = !showCommentInput">发表评论</button>
                </div>
            </div>


            <div class="mt-6" :class="{ hidden: curTab != 'comment' }">
                <comment-input placeholder="发表评论" @send-comment="sendComment" :show="showCommentInput"></comment-input>
                <div v-for="comment in appInfo.comments" :key="comment.id">
                    <comment-thread :data="comment"></comment-thread>
                </div>
                <div v-if="appInfo.comments?.length === 0" class="mt-4 text-gray-500 p-2">
                    暂无评论
                </div>
            </div>
            <div class="mt-6 text-gray-500 leading-8  p-2 lg:p-0" :class="{ hidden: curTab != 'desc' }">
                <div>{{ appInfo.desc }}</div>
            </div>
            <div class="mt-6  doc-content mce-content-body" :class="{ hidden: curTab != 'doc' }">
                <div v-html="updateRichTextPaths(appInfo.doc)"></div>
            </div>
        </div>
    </div>
</template>



<script>

import Prism from "prismjs";
import "prismjs/themes/prism.css"; // you can change

import CommentThread from './CommentThread.vue';
import CommentInput from './CommentInput.vue';
import VideoFav from './video-fav.vue';

import {
    getQueryParam, getLocalUserInfo, callApi, showLoginDialog, userActionInfo,
    EventBus, payOrder,
    userActionCountAddOne, userActionCountSubOne
} from '@/js/common';
import { CHAT_TO_USER, SHOW_QRCODE_DIALOG, CLOSE_QRCODE_DIALOG, } from '@/js/const';
import mixins from '@/js/mixins';

export default {
    mixins: [mixins],
    data() {
        return {
            curTab: "doc",
            showCommentInput: false,
            like: false,
            fav: false,
            orderInfo: null, // 订单信息
            appInfo: null
            // {
            //     "category": "SSR",
            //     "comments": [],
            //     "created_at": "2024-01-09 14:13:22",
            //     "desc": "所有动态前端内容， vue, react, angular, solidjs, jquery 等编写的服务经过本服务可以直接渲染出全部的 html, 只有 user-agent 为搜索引擎爬虫才会生效，该服务主要是为了搜索引擎收录内容。",
            //     "doc": null,
            //     "id": 1,
            //     "name": "服务端渲染",
            //     "price": 10,
            //     "sell_count": 1205,
            //     "star": 4.800000190734863,
            //     "status": 1,
            //     "user": {
            //         "avatar": "/a_xia_yun/upload/ab71d7a4aa2ddb880fd01d3ffc165dc3",
            //         "id": 2,
            //         "name": "官方"
            //     },
            //     "user_action": [
            //         {
            //             "action_type": "favorite",
            //             "count": 2,
            //             "target_id": 1
            //         },
            //         {
            //             "action_type": "like",
            //             "count": 2,
            //             "target_id": 1
            //         }
            //     ],
            //     "user_id": 2,
            //     "video": {
            //         "id": 1,
            //         "title": "公司产品介绍",
            //         "video_url": "/a_xia_yun/upload/10028d973fcd84a969b0791bfdaad31f"
            //     },
            //     "video_id": 1
            // }
        }
    },
    mounted() {
        window.Prism = window.Prism || {};
        window.Prism.manual = true;
        Prism.highlightAll(); // highlight your code on mount

        // 取得地址上应用id
        let id = this.$route.params.id;
        if (!id) {
            console.log("id not exist")
            return;
        }

        // 取得 ms 内容
        callApi(223, { id: id }, `get_micro_service_info`).then((res) => {
            this.appInfo = res.data
            this.appInfo.user_action = userActionInfo(this.appInfo.user_action)
            this.appInfo.comments.map(item => {
                item.user_action = userActionInfo(item.user_action)
            })

            this.setSiteInfo(this.appInfo.title, this.appInfo.desc)
            // 取得自己点赞信息，如果已经登录了
            if (getLocalUserInfo()) {
                callApi(227, { target_id: this.appInfo.id }, "get_micro_service_likes_fav").then(res => {
                    if (res.data.length != 2) {
                        console.error("falied get_micro_service_likes_fav: ", res.data)
                        return
                    }
                    this.like = res.data[0] ? true : false;
                    this.fav = res.data[1] ? true : false;
                })

                // 看自己是否下过单，如果下过单了就显示下单按钮
                this.haveOrdered()
            }
        })

    },
    methods: {
        clickLike() {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }
            callApi(this.like ? 244 : 234, { target_id: this.appInfo.id }, "click_like").then(res => {
                if (res.status == 0) {
                    if (!this.like) {
                        userActionCountAddOne(this.appInfo.user_action, "like")
                    } else {
                        userActionCountSubOne(this.appInfo.user_action, "like")
                    }
                    this.like = !this.like
                }
            })
        },
        haveOrdered() {
            callApi("have_order", { item_id: this.appInfo.id, item_type: "MicroService" }, "").then(res => {
                if (res.status < 0) {
                    console.error("have_order error: ", res.data)
                    return
                }
                this.orderInfo = res.data
            })
        },
        clickFav() {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }
            callApi(this.fav ? 243 : 233, { target_id: this.appInfo.id }, "click_fav").then(res => {
                if (res.status == 0) {
                    if (!this.fav) {
                        userActionCountAddOne(this.appInfo.user_action, "favorite")
                    } else {
                        userActionCountSubOne(this.appInfo.user_action, "favorite")
                    }
                    this.fav = !this.fav
                }
            })
        },
        chat(user_id) {
            try {
                EventBus.$emit(CHAT_TO_USER, user_id);
            } catch (e) {
                console.log("error", e);
            }
        },
        placeOrder() {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }

            callApi("place_order", {
                item_id: this.appInfo.id,
                item_type: "MicroService",
                recommend_id: this.getRecommandId()
            }).then(res => {
                if (res.status < 0) {
                    alert(res.msg)
                } else {
                    if (res.data.payment_status === "wait_pay") {
                        const pay_over = () => {
                            EventBus.$emit(CLOSE_QRCODE_DIALOG)
                            this.haveOrdered()
                        };
                        payOrder(res.data.order_number, pay_over).then(no => {
                            if (no) {
                                // 设置付款二维码
                                EventBus.$emit(SHOW_QRCODE_DIALOG, no, res.data.price, pay_over)
                            }
                        })
                    } else {
                        this.haveOrdered()
                    }
                    //this.$router.push({ name: 'user-center', params: { menu: 'my_orders' } })
                }
            })
        },
        sendComment(comment, success) {
            if (!getLocalUserInfo()) {
                showLoginDialog();
                return;
            }

            if (!comment) return;

            callApi(252, { target_id: this.appInfo.id, comment_type: "MicroService", content: comment }, "send_comment").then(res => {
                if (res.status == 0) {
                    this.showCommentInput = false;
                    let comment = res.data.comment;
                    // 插入到最前面
                    comment.user_action = userActionInfo(comment.user_action)
                    this.appInfo.comments.unshift(res.data.comment)
                    success();
                } else {
                    console.log(res)
                    alert(res.msg)
                }
            })
        }
    },
    components: {
        "comment-thread": CommentThread,
        "comment-input": CommentInput,
        "video-fav": VideoFav,
    }
}
</script>

<!-- <style scoped src="../assets/codepen.min.css"></style> -->
