<template>
    <div>
        <div v-if="app_list.length == 0" class="text-center text-gray-500">暂无数据</div>

        <div class="grid md:grid-cols-1 grid-cols-1 gap-8 md:place-items-center"
            :class="{ 'xl:grid-cols-3 lg:grid-cols-2': !user_center, 'xl:grid-cols-2 lg:grid-cols-2': user_center }">

            <component :is="!user_center ? 'router-link' : 'div'" v-for="item in app_list" :key="item.id"
                :to="{ name: 'app-detail', params: { id: item.id } }">
                <div class="card w-full md:w-96 bg-base-100 shadow-xl" :key="item.id" @click="click_app(item)">
                    <figure>
                        <div class="relative h-60 w-full image-container" @mouseenter="mouseEnterHandler"
                            @mouseleave="mouseLeaveHandler">
                            <img :src="ImageUrl(item.img)" :alt="item.title" class="w-full object-cover" />
                        </div>
                    </figure>
                    <div class="card-body">
                        <div class="card-actions justify-between items-center">
                            <div class="flex flex-row items-center gap-2">
                                <h2 class="card-title block max-w-40 truncate">
                                    {{ item.title }}
                                </h2>
                                <div v-if="item.is_new" class="badge badge-secondary">新品</div>
                            </div>
                            <div class="text-xs text-gray-400">已售 {{ item.sell_count }} 件</div>
                        </div>
                        <p>{{ item.desc }}</p>
                        <div class="card-actions justify-between pt-2">
                            <div class="flex flex-row gap-3 items-center">
                                <div class="text-sm text-red-500 ">¥ {{ item.price }}</div>
                                <div class="text-sm text-gray-500 line-through" v-if="item.old_price">¥ {{ item.old_price }}
                                </div>
                                <div class="tooltip tooltip-top tooltip-default"
                                    v-if="item.discount_to && getRemainDays(item.discount_to) > 0"
                                    :data-tip="'还有 ' + getRemainDays(item.discount_to) + ' 天恢复原价'">
                                    <div class=" badge badge-default badge-outline text-gray-500">限时</div>
                                </div>
                            </div>
                            <div class="flex flex-row gap-4 items-center">
                                <div v-if="!user_center" class="cursor-pointer" @click.stop.prevent="chat(item.user_id)">
                                    💬
                                </div>
                                <a v-if="!user_center && item.url" class="badge badge-outline cursor-pointer"
                                    @click.stop="try_it(item)" :href="item.url" target="_blank">
                                    体验
                                </a>
                                <a v-if="user_center" class="badge badge-outline cursor-pointer" @click.stop.prevent="showRemark(item)"
                                    :class="item.status == 1 ? 'badge-success' : 'badge-error'">
                                    {{ statusLable(item.status) }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </component>
            <dialog v-if="user_center" id="show_detail" ref="show_detail" class="modal">
                <div class="modal-box">
                    <label for="show_detail" class="btn btn-sm btn-circle absolute right-2 top-2"
                        @click="$refs.show_detail.close()">✕</label>
                    <div>操作</div>
                    <div class="flex flex-col gap-4 items-center">
                        <div class="flex flex-col md:flex-row justify-between p-4">
                            <div class="flex flex-col gap-2 items-center">
                                <div class="flex gap-2" v-if="currentItem.remark && currentItem.status != 1">
                                    <div class="text-xl font-semibold text-gray-500">
                                        审核提示： {{ currentItem.remark }}:</div>
                                    <div class="text-xl font-semibold text-gray-800">
                                        时间： {{ formatRelativeTime(currentItem.remark_at) }}</div>
                                </div>
                                <button class="btn btn-sm btn-error text-white"
                                    @click="offlineItem(currentItem)">下架</button>
                            </div>
                        </div>
                    </div>
                </div>
            </dialog>
        </div>
    </div>
</template>

<script>
import { callApi, EventBus } from '@/js/common'
import { CHAT_TO_USER, SELECTE_APP_TYPE } from '@/js/const'
import mixins from '@/js/mixins'
import router from '@/router'

export default {
    props: {
        user_center: {
            type: Boolean,
            default: false
        }
    },
    mixins: [mixins],
    data() {
        return {
            currentItem: {},
            app_list: [
                // {
                //     id: 1,
                //     title: 'CMS',
                //     desc: '用于内容管理系统',
                //     price: 999,
                //     old_price: 1999,
                //     is_new: true,
                //     sell_count: 23,
                //     img: 'https://daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg',
                //     name: "test_cms"
                // },
                // {
                //     id: 2,
                //     title: 'CMS',
                //     desc: '用于内容管理系统',
                //     price: 999,
                //     old_price: 1999,
                //     is_new: true,
                //     sell_count: 23,
                //     img: 'https://daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg',
                //     name: "test_cms",

                // },
                // {
                //     id: 3,
                //     title: 'CMS',
                //     desc: '用于内容管理系统',
                //     price: 999,
                //     old_price: 1999,
                //     is_new: true,
                //     sell_count: 23,
                //     img: 'https://daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg',
                //     name: "test_cms"
                // },
                // {
                //     id: 1,
                //     title: 'CMS',
                //     desc: '用于内容管理系统',
                //     price: 999,
                //     old_price: 1999,
                //     is_new: true,
                //     sell_count: 23,
                //     img: 'https://daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg',
                //     name: "test_cms"
                // },
                // {
                //     id: 2,
                //     title: 'CMS',
                //     desc: '用于内容管理系统',
                //     price: 999,
                //     old_price: 1999,
                //     is_new: true,
                //     sell_count: 23,
                //     img: 'https://daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg',
                //     name: "test_cms"
                // },

            ]
        }
    },
    created() {
        EventBus.$on(SELECTE_APP_TYPE, this.get_app_list)
    },
    beforeDestroy() {
        EventBus.$off(SELECTE_APP_TYPE, this.get_app_list)
    },
    mounted() {
        this.get_app_list();

    },
    methods: {
        showRemark(item) {
            this.currentItem = item;
            this.$refs.show_detail.showModal()
        },
        statusLable(status) {
            switch (status) {
                case 0:
                    return "审核中"
                case 1:
                    return "已上架"
                case -10:
                    return "已下架"
                default:
                    return "禁用"
            }
        },
        offlineItem(item) {
            // 下架
            callApi("offline_app", { id: item.id }, "offline").then((res) => {
                if (res.status == 0) {
                    this.get_app_list();
                    this.$refs.show_detail.close()
                }
            })
        },
        click_app(app) {
            console.log("click_app", app);
            if (this.user_center) {
                // 打开修改页
                this.$emit("edit-data", app)
            } else {
                //this.$router.push({ name: "app-detail", params: { id: app.id } })
                //location.href = "/app/" + app.id
            }

        },
        chat(user_id) {
            try {
                EventBus.$emit(CHAT_TO_USER, user_id);
            } catch (e) {
                console.log("error", e);
            }
        },
        try_it(app) {
            console.log("try_it", app);
        },
        get_app_list(category) {
            callApi(this.user_center ? 'get_my_apps' : 'app_list', { category: category }, `get_app_list`).then((res) => {
                this.app_list = res.data.map((item) => {
                    return item;
                })
            })
        },
        mouseEnterHandler(event) {
            // 获取到当前鼠标悬停的图片元素
            const img = event.target.querySelector("img");

            // 鼠标进入时，图片向上移动, 但底部不超过0
            img.style.top = `${-img.clientHeight + event.currentTarget.clientHeight}px`;
        },
        mouseLeaveHandler(event) {
            // 获取到当前图片元素
            const img = event.target.querySelector("img");
            // 鼠标离开时，图片回到初始位置
            img.style.top = "0";
        }
    },
    components: {
        router
    }
}
</script>

<style scoped>
.image-container {
    /* 或者其他固定高度值 */
    overflow: hidden;
    position: relative;
}

.image-container img {
    position: absolute;
    top: 0;
    /* 使图片移动更平滑 */
    transition: top 5s linear;

}
</style>