import Vue from 'vue';
import VueRouter from 'vue-router';

import AppPage from './components/pages/app.vue'
import AppDetail from './components/pages/app-detail.vue';
import DocList from './components/pages/doc-list.vue';
import MicroService from './components/pages/micro-service.vue';
import ServiceDetail from './components/pages/service-detail.vue';
import TechSupport from './components/pages/tech-support.vue';
import SupportDetail from './components/pages/support-detail.vue';
import UserCenter from './components/pages/user-center.vue';

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'app',
            component: AppPage
        },
        {
            path: '/app',
            name: 'app',
            component: AppPage
        },
        {
            path: '/app/:id',
            name: 'app-detail',
            component: AppDetail
        },
        {
            path: '/doc',
            name: 'doc',
            component: DocList
        },
        {
            path: '/doc/:id',
            name: 'doc-detail',
            component: DocList
        },
        {
            path: '/service',
            name: 'service',
            component: MicroService
        },
        {
            path: '/service/:id',
            name: 'service-detail',
            component: ServiceDetail
        },
        {
            path: '/support',
            name: 'support',
            component: TechSupport
        },
        {
            path: '/support/:id',
            name: 'support-detail',
            component: SupportDetail
        },
        {
            path: '/user-center/:menu',
            name: 'user-center',
            component: UserCenter
        },
        {
            path: '/landing-page/index.html',
            beforeEnter() { location.href = '/landing-page/index.html' }
        },
        {
            path: '*',
            redirect: '/'
        },

    ]
})

export default router;

