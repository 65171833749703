<template>
    <div>
        <header class="shadow fixed top-0 left-0 right-0 z-10">
            <div class="navbar bg-base-100">
                <div class="navbar-start">
                    <div class="dropdown lg:hidden">
                        <div tabindex="0" role="button" class="btn btn-ghost btn-circle">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M4 6h16M4 12h16M4 18h7" />
                            </svg>
                        </div>
                        <ul tabindex="0"
                            class="menu menu-sm dropdown-content mt-3 z-10 p-2 shadow bg-base-100 rounded-box w-52">
                            <li v-for="item in menuList" :key="item.name"
                                :class="{ 'text-blue-500': curMenu === item.name }">
                                <router-link :to="item.link">
                                    <a :href="item.link">{{ item.title }}</a>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="flex-1">
                        <a class="btn btn-ghost text-md lg:text-xl px-0" href="/index.html">夏之叶</a>
                    </div>
                </div>
                <div class="navbar-center hidden lg:flex">
                    <ul class="menu menu-horizontal px-1 gap-6 lg:gap-16">
                        <li v-for="item in menuList" :key="item.name" class="text-sm lg:text-lg"
                            :class="{ 'text-blue-500': curMenu === item.name }">
                            <router-link :to="item.link">
                                <a :href="item.link">{{ item.title }}</a>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="navbar-end">
                    <div class="flex gap-2">
                        <div id="searchBox" class="form-control hidden">
                            <input id="searchInput" type="text" placeholder="Search"
                                class="input input-bordered lg:w-auto" />
                        </div>
                        <button id="searchBtn" class="btn btn-ghost btn-circle hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </button>
                        <div v-if="user" class="dropdown dropdown-end">
                            <div class="indicator">
                                <span v-if="have_new_msg"
                                    class="indicator-item indicator-top indicator-end badge badge-xs badge-error top-1 right-1 shadow"></span>
                                <div tabindex="0" role="button" class="btn btn-ghost btn-circle avatar ">
                                    <div class="w-12 rounded-full ">
                                        <img alt="Tailwind CSS Navbar component"
                                            :src="user.avatar ? ImageUrl(user.avatar) : '/images/default_avatar.png'" />
                                    </div>
                                </div>
                            </div>
                            <ul tabindex="0"
                                class="mt-3 z-10 p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
                                <li>
                                    <router-link to="/user-center/user_info">
                                        <a class="justify-between">
                                            个人中心
                                        </a>
                                    </router-link>
                                </li>
                                <li>
                                    <a class="justify-between" @click="openChatBox()">
                                        消息
                                        <span class="badge badge-error text-white" v-if="have_new_msg">New</span>
                                    </a>
                                </li>
                                <li><a @click="logout()">退出</a></li>
                            </ul>
                        </div>
                        <div v-else>
                            <login-dialog></login-dialog>
                        </div>
                        <div>
                            <qrcode-dialog></qrcode-dialog>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="h-16 min-h-16"></div>
        <div class="chat-box fixed right-0 bottom-0 z-10">
            <chat-box></chat-box>
        </div>
    </div>
</template>

<script>
import { EventBus, getLocalUserInfo, userLogout, ImageUrl } from '../js/common.js';
import { USER_INFO, HAVE_NEW_MSG, USER_LOGOUT, OPEN_CHAT_BOX } from '../js/const.js';
import ChatBox from './chat-box.vue';
import LoginDialog from './login-dialog.vue';
import QrcodeDialog from './qrcode-dialog.vue';
import Mixins from '../js/mixins.js';

export default {
    props: {
        curMenu: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            have_new_msg: false,
            user: getLocalUserInfo(),
            message: 'Hello, Vue!',
            count: 0,
            menuList: [{
                name: "app",
                title: "应用",
                link: "/app"
            },
            {
                name: "doc",
                title: "教程",
                link: "/doc"
            },
            {
                name: "service",
                title: "微服务",
                link: "/service"
            },
            {
                name: "support",
                title: "技术服务",
                link: "/support"
            },
            {
                name: "download",
                title: "下载",
                link: "/landing-page/index.html"
            },
            ]

        };
    },
    mixins: [Mixins],
    methods: {
        logout() {
            this.user = null;
            localStorage.removeItem(USER_INFO)
            userLogout()

            EventBus.$emit(USER_LOGOUT);
        },
        openChatBox() {
            EventBus.$emit(OPEN_CHAT_BOX)
            this.have_new_msg = false;
        },
        onUserInfo(info) {
            this.user = info
            localStorage.setItem(USER_INFO, JSON.stringify(info));
        },
        onHaveNewMsg(c) {
            console.log("HAVE_NEW_MSG", c)
            if (c) {
                this.have_new_msg = true
            } else {
                this.have_new_msg = false
            }
        }
    },
    created() {
        EventBus.$on(USER_INFO, this.onUserInfo)
        EventBus.$on(HAVE_NEW_MSG, this.onHaveNewMsg)
    },
    beforeDestroy() {
        EventBus.$off(USER_INFO, this.onUserInfo)
        EventBus.$off(HAVE_NEW_MSG, this.onHaveNewMsg)
    },
    mounted() {

        const searchBox = document.getElementById('searchBox');
        const searchInput = document.getElementById('searchInput');
        const searchBtn = document.getElementById('searchBtn');

        searchBtn.addEventListener('click', () => {
            searchBox.classList.remove('hidden');
            searchInput.focus();
        });

        searchInput.addEventListener('blur', () => {
            if (searchInput.value === '') {
                searchBox.classList.add('hidden');
            }
        });

        searchInput.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                search();
            }
        });

        function search() {
            // const searchText = searchInput.value;
            // 进行搜索操作
        }


    },
    components: {
        "chat-box": ChatBox,
        "login-dialog": LoginDialog,
        "qrcode-dialog": QrcodeDialog
    }
};
</script>