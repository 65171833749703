<template>
    <div class="flex flex-col gap-4 mt-4">
        <div v-if="service_list.length == 0" class="text-sm text-center text-gray-500">暂无数据</div>
        <component :is="!user_center ? 'router-link' : 'div'" v-for="item in service_list" :key="item.id"
            :to="{ name: 'service-detail', params: { id: item.id } }">
            <div :key="item.id" @click.stop="click_service(item)"
                class="card bg-white shadow hover:shadow-lg transition-shadow duration-300 ease-in-out rounded-lg overflow-hidden">
                <div
                    class="flex flex-col md:flex-row justify-between p-4 bg-gray-50 hover:bg-blue-50 transition-colors duration-300 gap-4 md:gap-16">
                    <div class="flex flex-col gap-2">
                        <div class="text-xl font-semibold text-gray-800">{{ item.title }}</div>
                        <div class="text-md text-gray-600">功能: {{ item.desc }}</div>
                        <div class="flex flex-wrap gap-8 mt-2 items-center">
                            <div class="text-sm font-medium text-gray-700">价格:
                                <span class="text-red-500">¥{{ item.price }}</span>
                                <span class="text-sm text-gray-500 line-through ml-1" v-if="item.old_price">¥ {{
                                    item.old_price
                                }}</span>

                            </div>
                            <div class="tooltip tooltip-top tooltip-default"
                                v-if="item.discount_to && getRemainDays(item.discount_to) > 0"
                                :data-tip="'还有 ' + getRemainDays(item.discount_to) + ' 天恢复原价'">
                                <div class=" badge badge-default badge-outline text-gray-500">限时</div>
                            </div>
                            <div class="text-sm font-medium text-gray-700">销量: {{ item.sell_count }} 份</div>
                            <div class="text-sm font-medium text-gray-700">评分: <span class="text-yellow-400">{{
                                item.star.toFixed(1)
                            }} ⭐️</span></div>
                        </div>
                    </div>
                    <div class="flex flex-row md:flex-col gap-4 md:mt-0 justify-between md:justify-end items-center w-full md:w-auto"
                        style="min-width: 100px;">
                        <a v-if="user_center" class="badge badge-outline cursor-pointer"
                            @click.stop.prevent="showRemark(item)"
                            :class="item.status == 1 ? 'badge-success' : 'badge-error'">
                            {{ statusLable(item.status) }}
                        </a>
                        <div class="flex items-center gap-2 cursor-pointer hover:text-blue-500 transition-colors duration-300"
                            @click.stop.prevent="chat(item.user_id)">
                            💬
                            <span class="text-sm text-gray-700 hover:text-blue-500">联系</span>
                        </div>
                        <a @click.stop.prevent="download_try(item)" v-if="item.pkg"
                            class="bg-blue-500 hover:bg-blue-600 rounded-full px-4 py-2 text-center text-sm cursor-pointer text-white shadow hover:shadow-md transition-all duration-300">
                            <span v-if="item.price>0">下载试用</span>
                            <span v-else>下载</span>
                        </a>
                    </div>
                </div>
            </div>
        </component>

        <dialog v-if="user_center" id="show_detail" ref="show_detail" class="modal">
            <div class="modal-box">
                <label for="show_detail" class="btn btn-sm btn-circle absolute right-2 top-2"
                    @click="$refs.show_detail.close()">✕</label>
                <div>操作</div>
                <div class="flex flex-col gap-4 items-center">
                    <div class="flex flex-col md:flex-row justify-between p-4">
                        <div class="flex flex-col gap-2 items-center">
                            <div class="flex gap-2" v-if="currentItem.remark && currentItem.status != 1">
                                <div class="text-xl font-semibold text-gray-500">
                                    审核提示： {{ currentItem.remark }}:</div>
                                <div class="text-xl font-semibold text-gray-800">
                                    时间： {{ formatRelativeTime(currentItem.remark_at) }}</div>
                            </div>
                            <button class="btn btn-sm btn-error text-white" @click="offlineItem(currentItem)">下架</button>
                        </div>
                    </div>

                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
import { callApi, EventBus, showLoginDialog, getLocalUserInfo } from '@/js/common'
import { CHAT_TO_USER, SELECTE_MICROSERVICE_TYPE } from '@/js/const'
import mixins from '@/js/mixins'

export default {
    props: {
        user_center: {
            type: Boolean,
            default: false
        }
    },
    mixins: [mixins],
    data() {
        return {
            currentItem: {},
            service_list:
                [
                    // {
                    //     "category": "SSR",
                    //     "created_at": "2024-01-09 14:13:22",
                    //     "desc": "所有动态前端内容， vue, react, angular, solidjs, jquery 等编写的服务经过本服务可以直接渲染出全部的 html, 只有 user-agent 为搜索引擎爬虫才会生效，该服务主要是为了搜索引擎收录内容。",
                    //     "id": 1,
                    //     "title": "服务端渲染",
                    //     "price": 10,
                    //     "sell_count": 1205,
                    //     "star": 4.800000190734863,
                    //     "status": 1,
                    //     "user_id": 2
                    // }
                ]

        }

    },
    created() {
        EventBus.$on(SELECTE_MICROSERVICE_TYPE, this.get_service_list)
    },
    beforeDestroy() {
        EventBus.$off(SELECTE_MICROSERVICE_TYPE, this.get_service_list)
    },
    mounted() {
        this.get_service_list();

    },
    methods: {
        showRemark(item) {
            this.currentItem = item;
            this.$refs.show_detail.showModal()
        },
        statusLable(status) {
            switch (status) {
                case 0:
                    return "审核中"
                case 1:
                    return "已上架"
                case -10:
                    return "已下架"
                default:
                    return "禁用"
            }
        },
        offlineItem(item) {
            // 下架
            callApi("offline_ms", { id: item.id }, "offline").then((res) => {
                if (res.status == 0) {
                    this.get_service_list();
                    this.$refs.show_detail.close()
                }
            })
        },
        click_service(service) {
            console.log("click_service", service);
            if (this.user_center) {
                // 打开修改页
                this.$emit("edit-data", service)
            } else {
                //this.$router.push({ name: "service-detail", params: { id: service.id } })
                //location.href = "./service/" + service.id
            }
        },
        chat(user_id) {
            try {
                EventBus.$emit(CHAT_TO_USER, user_id);
            } catch (e) {
                console.log("error", e);
            }
        },
        download_try(item) {
            console.log("download_try", item);
            const user_info = getLocalUserInfo();
            if(!user_info){
                // 登录
                showLoginDialog();
                return;
            }

            // 添加下载元素并且下载
            const element = document.createElement('a');
            element.setAttribute('href', this.FileUrl(item.pkg?.package_path));
            element.setAttribute('download', item.title+'.zip');
            document.body.appendChild(element);
            element.click();

            // 然后删除这个元素
            document.body.removeChild(element);
        },
        get_service_list(category) {
            callApi(this.user_center ? 'get_my_ms' : 'mservice_list', { category: category }, `get_microservice_list`).then((res) => {
                this.service_list = res.data.map((item) => {
                    return item;
                })
            })
        }

    }
}
</script>