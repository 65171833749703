<template>
    <transition name="slide-fade">
        <div class="space-y-2 px-2" v-if="show">
            <textarea v-model="comment" class="w-full p-2 border rounded text-sm placeholder-gray-400"
                :placeholder="placeholder" rows="3"></textarea>
            <div class="flex items-center justify-end">
                <emoji-button @emoji-added="addEmojiToComment"></emoji-button>
                <button @click="sendComment"
                    class="ml-2 bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded text-xs">发送</button>

            </div>
        </div>
    </transition>
</template>
  
<script>
import EmojiButton from "./EmojiButton.vue";

export default {
    props: ["placeholder", "show"],
    components: {
        EmojiButton,
    },
    data() {
        return {
            comment: '',
        };
    },
    methods: {
        addEmojiToComment(emoji) {
            this.comment += emoji;
        },
        sendComment() {
            // Logic to send the comment
            console.log("Sending comment: " + this.comment);
            this.$emit("send-comment", this.comment, () => {
                this.comment = ''; // Clear the input after sending
            });


        },
    },
};
</script>


<style scoped>
.v-enter-from,
/* vue3*/
.v-leave-to {
    opacity: 0;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s;
}

.slide-fade-enter-active {
    transition: all .2s ease;
}

.slide-fade-leave-active {
    transition: all .2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for below version 2.1.8 */
    {
    transform: translateY(-10px);
    opacity: 0;
}
</style>