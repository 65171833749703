<template>
    <div>
        <!-- Package List -->
        <div class="bg-white shadow-md rounded px-4 md:px-8 pt-6 pb-8 mb-4">
            <div class="flex flex-row justify-between items-center">
                <h2 class="text-xl font-bold mb-4">安装包</h2>
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                    @click="showModal()">上传</button>
            </div>
            <div v-if="packages.length == 0" class="text-center text-gray-500">暂无数据</div>
            <div class="container mx-auto py-8">
                <!-- Mobile view, shown only on small screens -->
                <div class="block lg:hidden xl:hidden xxl:hidden">
                    <!-- Card representation for each item, repeat structure for additional items -->
                    <div v-for="item in packages" :key="item.id" class="bg-white p-4 rounded-lg shadow mb-4 space-y-2">
                        <div><strong>名称: </strong>
                            <a :href="FileUrl(item.package_path)" :download="item.title + '.zip'" class="text-blue-500">{{
                                item.title
                            }}</a>
                        </div>
                        <div><strong>类别:</strong> {{ getTypeName(item.type) }}</div>
                        <div><strong>版本:</strong> {{ item.version }}</div>
                        <div><strong>上传时间:</strong> {{ formatRelativeTime(item.update_at) }}</div>
                        <div class="flex mt-4 w-full justify-end">
                            <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-2"
                                @click="showModal(item.id)">编辑</button>
                            <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                @click="deletePackage(item.id)">删除</button>
                        </div>
                    </div>
                </div>

                <!-- Desktop view, hidden on small screens -->
                <div class="hidden  lg:block xl:block xxl:block">
                    <table v-if="packages.length > 0" class="table-auto w-full mb-4">
                        <thead>
                            <tr>
                                <th class="border px-4 py-2">名称</th>
                                <th class="border px-4 py-2">类别</th>
                                <th class="border px-4 py-2">版本</th>
                                <th class="border px-4 py-2">上传时间</th>
                                <th class="border px-4 py-2 w-40">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in packages" :key="item.id">
                                <td class="border px-4 py-2 text-center">
                                    <a :href="FileUrl(item.package_path)" :download="item.title + '.zip'"
                                        class="text-blue-500">{{
                                            item.title }}</a>
                                </td>
                                <td class="border px-4 py-2 text-center">{{ getTypeName(item.type) }}</td>
                                <td class="border px-4 py-2 text-center">{{ item.version }}</td>
                                <td class="border px-4 py-2 text-center">{{ formatRelativeTime(item.update_at) }}</td>
                                <td class="border px-4 py-2 text-center space-x-2">
                                    <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
                                        @click="showModal(item.id)">编辑</button>
                                    <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                        @click="deletePackage(item.id)">删除</button>
                                </td>
                            </tr>
                            <!-- Additional rows as needed -->
                        </tbody>
                    </table>
                </div>
            </div>
            <dialog id="edit_pkg" class="modal">

                <div class="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100 modal-box">
                    <form method="dialog">
                        <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                    </form>
                    <form class="card-body" @submit.prevent="submitPackage">
                        <h1 class="text-2xl font-bold mb-4">{{ currentPackage.id ? '修改' : '上传' }}</h1>
                        <div class="form-control">
                            <label class="label block text-sm font-medium text-gray-700">包名</label>
                            <input type="text" class="input input-bordered w-full " required placeholder="请输入包名"
                                v-model="currentPackage.title">
                        </div>
                        <div class="form-control">
                            <label class="label block text-sm font-medium text-gray-700">上传zip包</label>
                            <input type="file" class="file-input file-input-bordered file-input-primary w-full max-w-xs "
                                :required="!currentPackage.id" accept=".zip" @change="uploadPkg" ref="package_file">
                                 <!-- 进度 -->
                            <progress class="progress  w-full mt-1" v-show="pkg_progress>0" :value="pkg_progress" max="100"></progress>
                        </div>
                        <div class="form-control">
                            <label class="label block text-sm font-medium text-gray-700">包类型</label>
                            <select class="select select-bordered w-full " required v-model="currentPackage.type">
                                <option v-for="t in types" :key="t.value" :value="t.value">{{ t.label }}</option>
                            </select>
                        </div>
                        <div>
                            <label class="label block text-sm font-medium text-gray-700">版本号</label>
                            <input type="text" class="input input-bordered w-full" placeholder="1.0.0" required
                                v-model="currentPackage.version">
                        </div>
                        <button type="submit" class="btn btn-primary">提交</button>
                    </form>
                </div>
            </dialog>
        </div>
    </div>
</template>

<script>
import {
    callApi, sendFile, getOptions
} from '@/js/common';
import mixins from '@/js/mixins';

export default {
    mixins: [mixins],
    data() {
        return {
            types: [],
            // 模拟数据列表
            packages: [
                // {
                //     id: 1,
                //     title: "Example Package",
                //     package_path: "",
                //     type: "Library",
                //     version: "1.0.0",
                //     update_at: "2023-01-01",
                // },
                // 可以添加更多的包
            ],
            // 用于表单的数据
            currentPackage: {
                title: "",
                package_path: null,
                update_at: "",
                type: "",
                version: ""
            },
            pkg_progress:0
        };
    },
    async mounted() {
        await this.getTypeList()
        await this.getPkgList()
    },
    methods: {
        async getTypeList() {
            try {
                const res = await getOptions('/package/type')
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                } else {
                    this.types = res.data.options
                }
            } catch (e) {
                console.error("get type list error", e)
            }
        },
        getTypeName(t) {
            return this.types.find(type => type.value === t)?.label
        },
        async getPkgList() {
            try {
                const res = await callApi("pkg_list")
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                } else {
                    this.packages = res.data
                }
            } catch (e) {
                console.error("get pkg list error", e)
            }
        },
        showModal(id) {
            // 显示模态对话框
            const modal = document.getElementById('edit_pkg');
            if (modal.showModal) {
                modal.showModal();
            } else {
                console.error("The <dialog> API is not supported by this browser");
            }

            this.$refs.package_file.value = "";
            if (id) {
                // 获取指定包的数据
                const packageIndex = this.packages.findIndex(pkg => pkg.id === id);
                if (packageIndex !== -1) {
                    this.currentPackage = this.packages[packageIndex];
                }
            } else {
                // 重置表单数据
                this.currentPackage = {
                    title: "",
                    package_path: null,
                    update_at: "",
                    type: "",
                    version: ""
                };
            }
        },
        closeModal() {
            // 关闭模态对话框
            const modal = document.getElementById('edit_pkg');
            modal.close();
        },
        async submitPackage() {
            // 提交表单处理方法
            console.log("Submitting package:", this.currentPackage);
            const res = await callApi("upsert_pkg", this.currentPackage, "upsert_pkg");
            if (res.status < 0) {
                alert(res.msg)
                return;
            }
            await this.getPkgList();
            this.$refs.package_file.value = "";
            this.closeModal();
        },
        async deletePackage(packageId) {
            if (!confirm("确定删除该包吗?")) {
                return;
            }

            // 删除某个包
            const res = await callApi("remove_pkg", { id: packageId }, "remove_pkg");
            if (res.status < 0) {
                alert(res.msg)
                return;
            }
            await this.getPkgList();
        },
        // 可以添加其他方法，如编辑包等
        async uploadPkg(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const file = input.files[0];
                const res = await sendFile(file, "map=pkg", (p)=>{
                    this.pkg_progress = p
                })
                this.pkg_progress = 0;
                if (res.status < 0) {
                    alert(res.msg)
                    return;
                }

                this.currentPackage.package_path = res.data.value;
            }
        }
    }
}
</script>