<template>
    <div class="flex flex-col gap-4 mt-4">
        <div v-if="!order_list.length" class="text-center text-gray-500">暂无订单</div>
        <div v-for="item in  order_list " :key="item.id"
            class="card bg-white shadow hover:shadow-lg transition-shadow duration-300 ease-in-out rounded-lg overflow-hidden">
            <div
                class="flex flex-col md:flex-row justify-between p-4 bg-gray-50 hover:bg-blue-50 transition-colors duration-300 gap-4 md:gap-16">
                <div class="flex flex-col gap-2">
                    <div class="flex gap-2 cursor-pointer" @click="click_order(item)">
                        <div class="text-xl font-semibold text-gray-500">
                            {{ item.label }}:</div>
                        <div class="text-xl font-semibold text-gray-800">
                            {{ item.info.title }}</div>
                    </div>
                    <div class="text-md text-gray-600">{{ item.info.desc }}</div>
                    <div class="flex flex-wrap gap-8 mt-2 items-center">
                        <div class="text-sm font-medium text-gray-700">价格: <span class="text-red-500">¥{{ item.price
                        }}</span></div>
                        <div v-if="item.payment_time" class="text-sm font-medium text-gray-700">购买时间: {{
                            formatRelativeTime(item.payment_time) }}
                        </div>
                        <div v-else class="text-sm font-medium text-gray-700">
                            状态: <span class="text-red-500">{{ item.status?.label }}</span>
                        </div>

                        <div v-if="item.payment_status == 'wait_pay'">
                            <button class="btn btn-sm text-blue-400" @click.stop="pay(item)">支付</button>
                        </div>
                        <div v-if="item.payment_status == 'pay_successed'"
                            class="text-sm font-medium text-gray-700 flex items-center gap-2">
                            <span>评分:</span>
                            <div class="rating" @click.stop="() => { }">
                                <input v-for="i in 5" :key="i" type="radio" :name="`order-` + item.id"
                                    class="mask mask-star-2 bg-orange-400" :checked="get_star(item.star) == i"
                                    @change.stop="set_star(item, i)" />
                            </div>
                        </div>
                        <div v-if="item.item_key" class="text-sm font-medium text-gray-700"
                            @click.stop="taggle_show_key(item.item_key)">
                            密钥: <span class="text-red-500 cursor-pointer" v-if="has_show_key(item.item_key)">{{ item.item_key }}</span>
                            <span class="text-gray-500 cursor-pointer" v-else
                                @click.stop="taggle_show_key(item.item_key)">点击显示</span>
                                <!-- 复制 -->
                            <button class="btn btn-sm text-blue-400 ml-1" @click.stop="copy_key($event, item.item_key)">复制</button>
                            <span class="text-gray-500 ml-1 copy-tip"></span>
                        </div>

                    </div>
                </div>
                <div class="flex flex-row md:flex-col gap-4 md:mt-0 justify-between md:justify-end w-full md:w-auto"
                    style="min-width: 100px;">
                    <div class="flex items-center gap-2 cursor-pointer hover:text-blue-500 transition-colors duration-300"
                        @click.stop="chat(item.info.user_id)">
                        💬
                        <span class="text-sm text-gray-700 hover:text-blue-500">联系</span>
                    </div>
                    <div v-if="item.info.pkg?.package_path && item.payment_status == 'pay_successed'"
                        class="bg-blue-500 hover:bg-blue-600 rounded-full px-4 py-2 text-center text-sm cursor-pointer text-white shadow hover:shadow-md transition-all duration-300">
                        <a :href="FileUrl(item.info.pkg?.package_path)" :download="item.info.title + '.zip'">
                            下载
                        </a>
                    </div>
                    <div v-else-if="item.info.pkg?.package_path && item.item_type == 'MicroService'"
                        class="bg-blue-500 hover:bg-blue-600 rounded-full px-4 py-2 text-center text-sm cursor-pointer text-white shadow hover:shadow-md transition-all duration-300">
                        <a :href="FileUrl(item.info.pkg?.package_path)" :download="item.info.title + '.zip'">
                            下载试用
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    callApi, EventBus, payOrder
} from '@/js/common';
import { CHAT_TO_USER, SHOW_QRCODE_DIALOG, CLOSE_QRCODE_DIALOG } from '@/js/const';
import mixins from '@/js/mixins';

export default {
    mixins: [mixins],
    data() {
        return {
            show_item_key: [],
            order_list:
                [
                    // {
                    //     "label": "应用",
                    //     "created_at": "2024-01-25 11:19:54",
                    //     "id": 1,
                    //     "info": {
                    //         "category": "ProdIntro",
                    //         "id": 1,
                    //         "package_id": null,
                    //         "pkg": null,
                    //         "title": "公司产品介绍页",
                    //         "desc": "所有动态前端内容， vue, react, angular, solidjs, jquery 等编写的服务经过本服务可以直接渲染出全部的 html, 只有 user-agent 为搜索引擎爬虫才会生效，该服务主要是为了搜索引擎收录内容。",
                    //         "user_id": 3
                    //     },
                    //     "item_id": 1,
                    //     "item_key": "active",
                    //     "item_type": "AppService",
                    //     "order_number": "innerNo110",
                    //     "payment_method": "wechat",
                    //     "payment_status": "pay_successed",
                    //     "payment_time": "2024-01-25 00:00:00",
                    //     "platform_order_number": null,
                    //     "price": 233,
                    //     "refund_amount": 0,
                    //     "refund_time": null,
                    //     "star": 4,
                    //     "updated_at": "2024-01-25 11:23:09",
                    //     "user_id": 1,
                    //     "status": {
                    //         "label": "支付成功",
                    //         "value": "pay_successed"
                    //     },
                    // },
                    // {
                    //     "label": "微服务",
                    //     "created_at": "2024-01-25 11:46:06",
                    //     "id": 2,
                    //     "info": {
                    //         "id": 1,
                    //         "package_id": null,
                    //         "pkg": null,
                    //         "title": "服务端渲染",
                    //         "user_id": 2
                    //     },
                    //     "item_id": 1,
                    //     "item_key": null,
                    //     "item_type": "MicroService",
                    //     "order_number": "innerNo111",
                    //     "payment_method": "alipay",
                    //     "payment_status": "pay_expired",
                    //     "payment_time": null,
                    //     "platform_order_number": null,
                    //     "price": 123.66,
                    //     "refund_amount": 0,
                    //     "refund_time": null,
                    //     "star": 0,
                    //     "updated_at": "2024-01-25 11:46:06",
                    //     "user_id": 1,
                    //     "status": {
                    //         "label": "未支付",
                    //         "value": "pay_successed"
                    //     },
                    // }

                ]

        }

    },
    mounted() {
        this.get_order_list();
    },
    methods: {
        click_order(order) {
            console.log("click_order", order);
            if (order.item_type == "AppService") {
                this.$router.push({ name: "app-detail", params: { id: order.item_id } });
            } else if (order.item_type == "MicroService") {
                this.$router.push({ name: "service-detail", params: { id: order.item_id } });
            } else if (order.item_type == "TechService") {
                this.$router.push({ name: "support-detail", params: { id: order.item_id } });
            }
        },
        chat(user_id) {
            try {
                EventBus.$emit(CHAT_TO_USER, user_id);
            } catch (e) {
                console.log("error", e);
            }
        },
        set_star(item, rating) {
            console.log("set_star", item, rating);
            item.star = rating
            callApi("order_rating", { id: item.id, star: rating }, `set_rating`).then((res) => {
                if (res.status < 0) {
                    console.log("set_rating error", res.msg)
                }
            })
        },
        get_star(rating) {
            // 取整数
            return Math.ceil(rating)
        },
        pay(item) {
            console.log("pay order", item.id)
            const pay_over = () => {
                EventBus.$emit(CLOSE_QRCODE_DIALOG)
                this.get_order_list() // 刷新列表
            };
            payOrder(item.order_number, pay_over).then(no => {
                if (no) {
                    // 设置付款二维码
                    EventBus.$emit(SHOW_QRCODE_DIALOG, no, item.price, pay_over)
                }
            })
        },
        get_order_list() {
            callApi("user_order_list", {}, `get_order_list`).then((res) => {
                if (res.status < 0) {
                    alert(res.msg);
                    return;
                }

                this.order_list = [];
                for (let c of res.data) {
                    const { label, orders } = c;
                    for (let o of orders) {
                        this.order_list.push({ label, ...o })
                    }
                }
            })
        },
        taggle_show_key(key) {
            if (this.show_item_key.includes(key)) {
                this.show_item_key.splice(this.show_item_key.indexOf(key), 1)                
            } else {
                this.show_item_key.push(key)
            }
        },
        copy_key(event, key){
            navigator.clipboard.writeText(key);
            event.target.parentElement.querySelector(".copy-tip").innerHTML = "复制成功"
            setTimeout(() => {
                event.target.parentElement.querySelector(".copy-tip").innerHTML = ""
            }, 1000)
        },
        has_show_key(key) {
            return this.show_item_key.includes(key)
        }

    }
}
</script>