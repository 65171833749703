<template>
    <div class="flex flex-col gap-4 mt-1 lg:mt-4">
        <div v-if="support_list.length == 0" class="text-sm text-center text-gray-500">暂无数据</div>
        <component :is="!user_center ? 'router-link' : 'div'" v-for="item in support_list" :key="item.id"
            :to="{ name: 'support-detail', params: { id: item.id } }">
            <div class="card shadow p-4 cursor-default hover:shadow-lg" @click="click_support(item)">
                <div class="flex flex-row justify-between p-2 ">
                    <div class="flex flex-col gap-2">
                        <div class="text-lg font-bold">{{ item.team_name }}</div>
                        <div class="text-md">{{ item.skill_desc }}</div>
                        <div class="flex flex-row gap-6 lg:gap-20 text-sm mt-2 font-bold flex-wrap">
                            <div class="text-sm font-medium text-gray-700">单价:
                                <span class="text-red-500">¥{{ item.price }}/小时</span>
                                <span class="text-sm text-gray-500 line-through ml-1" v-if="item.old_price">¥ {{
                                    item.old_price
                                }}/小时</span>
                            </div>
                            <div class="tooltip tooltip-top tooltip-default"
                                v-if="item.discount_to && getRemainDays(item.discount_to) > 0"
                                :data-tip="'还有 ' + getRemainDays(item.discount_to) + ' 天恢复原价'">
                                <div class=" badge badge-default badge-outline text-gray-500">限时</div>
                            </div>
                            <div class="text-sm font-medium text-gray-700">接单数: {{ item.order_count }} 份</div>
                            <div class="text-sm font-medium text-gray-700">评分: <span class="text-yellow-400">{{
                                item.star?.toFixed(1)
                            }} ⭐️</span></div>
                            <div class="flex flex-row gap-8">
                                <a v-if="user_center" class="badge badge-outline cursor-pointer"
                                    @click.stop.prevent="showRemark(item)"
                                    :class="item.status == 1 ? 'badge-success' : 'badge-error'">
                                    {{ statusLable(item.status) }}
                                </a>
                                <div v-if="!user_center" class="flex flex-row gap-2 cursor-pointer hover:text-blue-400"
                                    @click.stop.prevent="chat(item.user_id)">
                                    <div
                                        class="flex items-center gap-2 cursor-pointer hover:text-blue-500 transition-colors duration-300">
                                        💬
                                        <span class="text-sm text-gray-700 hover:text-blue-500">联系</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </component>

        <dialog v-if="user_center" id="show_detail" ref="show_detail" class="modal">
            <div class="modal-box">
                <label for="show_detail" class="btn btn-sm btn-circle absolute right-2 top-2"
                    @click="$refs.show_detail.close()">✕</label>
                <div class="flex flex-col gap-4 items-center">
                    <div class="flex flex-col md:flex-row justify-between p-4">
                        <div class="flex flex-col gap-2 items-center">
                            <div class="flex gap-2" v-if="currentItem.remark && currentItem.status != 1">
                                <div class="text-xl font-semibold text-gray-500">
                                    审核提示： {{ currentItem.remark }}:</div>
                                <div class="text-xl font-semibold text-gray-800">
                                    时间： {{ formatRelativeTime(currentItem.remark_at) }}</div>
                            </div>
                            <button class="btn btn-sm btn-error text-white" @click="offlineItem(currentItem)">下架</button>
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
import { callApi, EventBus } from '@/js/common'
import { CHAT_TO_USER, SELECTE_SUPPORT_TYPE } from '@/js/const'
import mixins from '@/js/mixins'

export default {
    props: {
        user_center: {
            type: Boolean,
            default: false
        }
    },
    mixins: [mixins],
    data() {
        return {
            currentItem: {},
            support_list: [
                // {
                //     "category": "TPM",
                //     "created_at": "2024-01-11 12:17:06",
                //     "id": 1,
                //     "order_count": 203,
                //     "price": 300,
                //     "skill_desc": "以问题为导向，通过成熟的技术，解决问题。形成解决现有问题，可迭代解决未知问题的系统给客户。",
                //     "star": 4.900000095367432,
                //     "status": 1,
                //     "team_name": "官方项目整包",
                //     "user_id": 2,
                //     "video_id": 1
                // }
            ]
        }
    },
    created() {
        EventBus.$on(SELECTE_SUPPORT_TYPE, this.get_support_list)
    },
    beforeDestroy() {
        EventBus.$off(SELECTE_SUPPORT_TYPE, this.get_support_list)
    },
    mounted() {
        this.get_support_list();

    },
    methods: {
        showRemark(item) {
            this.currentItem = item;
            this.$refs.show_detail.showModal()
        },
        statusLable(status) {
            switch (status) {
                case 0:
                    return "审核中"
                case 1:
                    return "已上架"
                case -10:
                    return "已下架"
                default:
                    return "禁用"
            }
        },
        offlineItem(item) {
            // 下架
            callApi("offline_service", { id: item.id }, "offline").then((res) => {
                if (res.status == 0) {
                    this.get_support_list();
                    this.$refs.show_detail.close()
                }
            })
        },
        click_support(item) {
            console.log("click_support", item);
            if (this.user_center) {
                // 打开修改页
                this.$emit("edit-data", item)
            } else {
                //this.$router.push({ name: "support-detail", params: { id: item.id } })
                //location.href = "./support/" + item.id
            }
        },
        chat(user_id) {
            try {
                EventBus.$emit(CHAT_TO_USER, user_id);
            } catch (e) {
                console.log("error", e);
            }
        },
        get_support_list(category) {
            callApi(this.user_center ? 'get_my_tech_support' : 'tech_support_list', { category: category }, `get_support_list`).then((res) => {
                this.support_list = res.data.map((item) => {
                    return item;
                })
            })
        }

    }
}
</script>