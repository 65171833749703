<template>
    <div class="lg:px-12 w-full flex flex-col lg:flex-row items-none lg:items-end justify-center gap-2 mt-2">
        <div class="bg-black max-w-5xl w-full">
            <video :src="FileUrl(video_url)" style="aspect-ratio: 16/9; width: 100%; height: 100%; object-fit: contain;"
                autoplay controls></video>
        </div>
        <div class="flex flex-row justify-between items-center scale-60 lg:scale-100">
            <div class="text-md lg:text-xl lg:font-bold lg:hidden max-w-2xl truncate mx-2">{{ title }}</div>
            <div
                class="px-4 py-2 lg:px-2 lg:w-16 lg:py-4 flex flex-row-reverse lg:flex-col gap-10 justify-start lg:justify-end items-start">
                <!-- share -->
                <div class="flex flex-col gap-1 items-center cursor-pointer" @click="$emit('click-share')">
                    <svg class="w-8 h-8" viewBox="0 0 40 40" style="-webkit-print-color-adjust: exact;" fill="none"
                        version="1.1">
                        <path rx="0" ry="0" fill="#55cc55" stroke="#55cc55" stroke-width="1"
                            d="M28.428,27.231C27.250,27.232,26.137,27.829,25.407,28.854L12.287,21.593C12.472,20.873,12.472,20.110,12.287,19.390L25.391,12.129C26.551,13.773,28.612,14.249,30.267,13.253C31.922,12.258,32.685,10.083,32.073,8.110C31.460,6.137,29.650,4.943,27.793,5.287C25.936,5.631,24.575,7.412,24.574,9.498C24.572,9.874,24.618,10.248,24.713,10.609L11.609,17.869C10.596,16.434,8.872,15.868,7.317,16.462C5.762,17.056,4.718,18.678,4.718,20.500C4.718,22.322,5.762,23.944,7.317,24.538C8.872,25.132,10.596,24.566,11.609,23.131L24.713,30.391C24.618,30.752,24.572,31.126,24.574,31.502C24.574,33.860,26.300,35.773,28.428,35.773C30.557,35.773,32.283,33.860,32.283,31.502C32.283,29.143,30.557,27.231,28.428,27.231ZZM28.428,6.936C29.705,6.936,30.741,8.083,30.741,9.498C30.741,10.914,29.705,12.061,28.428,12.061C27.151,12.061,26.116,10.914,26.116,9.498C26.116,8.083,27.151,6.936,28.428,6.936ZZM8.572,23.062C7.295,23.062,6.259,21.915,6.259,20.500C6.259,19.085,7.295,17.937,8.572,17.937C9.849,17.937,10.884,19.085,10.884,20.500C10.884,21.915,9.849,23.062,8.572,23.062ZZM28.428,34.064C27.151,34.064,26.116,32.917,26.116,31.502C26.116,30.086,27.151,28.939,28.428,28.939C29.705,28.939,30.741,30.086,30.741,31.502C30.741,32.917,29.705,34.064,28.428,34.064ZZ" />
                    </svg>
                    <span class="text-xs" style="opacity: 0;">{{ shares }}</span>
                </div>
                <!-- fav -->
                <div class="flex flex-col gap-1 items-center cursor-pointer" @click="$emit('click-fav')">
                    <svg class="w-8 h-8" viewBox="0 0 50 50" style="-webkit-print-color-adjust: exact;" fill="none"
                        version="1.1">
                        <path rx="0" ry="0" :fill="fav ? '#fad000' : '#ff000000'" stroke="#fad000" stroke-width="3"
                            d="M25.000,35.979L37.875,43.750L34.458,29.104L45.833,19.250L30.854,17.958L25.000,4.167L19.146,17.958L4.167,19.250L15.521,29.104L12.125,43.750L25.000,35.979ZZ" />
                    </svg>
                    <span class="text-xs text-gray-500 select-none">{{ favorites ?? 0 }}</span>
                </div>
                <!-- like -->
                <div class="flex flex-col gap-1 items-center cursor-pointer" @click="$emit('click-like')">
                    <svg class="w-8 h-8" viewBox="0 0 55 55" style="-webkit-print-color-adjust: exact;" fill="none"
                        version="1.1">
                        <path rx="0" ry="0" :fill="like ? '#fc3737' : '#ff000000'" stroke="#fc3737" stroke-width="2"
                            d="M24.500,45.369L21.540,42.564C11.025,32.640,4.083,26.074,4.083,18.063C4.083,11.496,9.024,6.375,15.313,6.375C18.865,6.375,22.275,8.096,24.500,10.795C26.725,8.096,30.135,6.375,33.688,6.375C39.976,6.375,44.917,11.496,44.917,18.063C44.917,26.074,37.975,32.640,27.460,42.564L24.500,45.369ZZ" />
                    </svg>
                    <span class="text-xs text-gray-500 select-none">{{ likes ?? 0 }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mixins from '@/js/mixins';
export default {
    mixins: [mixins],
    props: {
        title: String,
        video_url: String,
        shares: Number,
        likes: Number,
        favorites: Number,
        like: Boolean,
        fav: Boolean
    }
}
</script>