<template>
    <div>
        <div id="qs-header">
            <qs-header cur-menu="support"></qs-header>
        </div>
        <div class="max-w-7xl px-0 lg:px-8 flex flex-col mx-auto">
            <div id="service-content">
                <support-content></support-content>
            </div>
        </div>
    </div>
</template>


<script>
import QsHeader from '../qs-header.vue';
import SupportContent from '../support-content.vue';

export default {
    components: {
        "qs-header": QsHeader,
        "support-content": SupportContent,
    }
}
</script>