<template>
    <div class="flex flex-col gap-4 mt-4">
        <div v-if="!fav_list.length" class="text-center text-gray-500">暂无收藏</div>
        <div v-for="item in fav_list" :key="item.id" @click="click_fav(item)"
            class="card bg-white shadow hover:shadow-lg transition-shadow duration-300 ease-in-out rounded-lg overflow-hidden">
            <div
                class="flex flex-col md:flex-row justify-between p-4 bg-gray-50 hover:bg-blue-50 transition-colors duration-300 gap-4 md:gap-16">
                <div class="flex flex-col gap-2">
                    <div class="flex gap-2 cursor-pointer">
                        <div class="text-xl font-semibold text-gray-500">
                            {{ item.target_label?.label }}:</div>
                        <div class="text-xl font-semibold text-gray-800">
                            {{ item.title }}</div>
                    </div>
                    <div class="text-md text-gray-600">{{ item.desc }}</div>
                    <div class="flex flex-wrap gap-8 mt-2 items-center">
                        <div v-if="item.created_at" class="text-sm font-medium text-gray-700">收藏时间: {{
                            formatRelativeTime(item.created_at) }}
                        </div>
                        <div v-else class="text-sm font-medium text-gray-700">
                            状态: <span class="text-red-500">{{ item.status?.label }}</span>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row md:flex-col gap-4 md:mt-0 justify-between md:justify-end w-full md:w-auto"
                    style="min-width: 100px;">
                    <div v-if="item.target_type !== 'Tutorial'"
                        class="flex items-center gap-2 cursor-pointer hover:text-blue-500 transition-colors duration-300"
                        @click.stop="chat(item.pkg_user_id)">
                        💬
                        <span class="text-sm text-gray-700 hover:text-blue-500">联系</span>
                    </div>
                    <div>
                        <button class="btn btn-error btn-sm text-white" @click.stop="remove_fav(item)">删除</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    callApi, EventBus
} from '@/js/common';

import { CHAT_TO_USER } from '@/js/const';
import mixins from '@/js/mixins';

export default {
    mixins: [mixins],
    data() {
        return {
            fav_list:
                [
                    /*
                        {
                            "action_type": "favorite",
                            "created_at": "2024-01-09 20:28:03",
                            "desc": "专门展示公司产品",
                            "id": 2,
                            "package_id": 1,
                            "pkg_user_id": 3,
                            "target_id": 1,
                            "target_label": {
                                "label": "应用",
                                "value": "AppService"
                            },
                            "target_type": "AppService",
                            "title": "公司产品介绍页",
                            "user_id": 2
                        }
                        */
                ]

        }

    },
    mounted() {
        this.get_fav_list();
    },
    methods: {
        click_fav(fav) {
            console.log("click_fav", fav);
            if (fav.target_type == "AppService") {
                this.$router.push({ name: "app-detail", params: { id: fav.target_id } });
            } else if (fav.target_type == "MicroService") {
                this.$router.push({ name: "service-detail", params: { id: fav.target_id } });
            } else if (fav.target_type == "TechService") {
                this.$router.push({ name: "support-detail", params: { id: fav.target_id } });
            } else if (fav.target_type == "Tutorial") {
                this.$router.push({ name: "doc-detail", params: { id: fav.target_id } });
            }
        },
        chat(user_id) {
            try {
                EventBus.$emit(CHAT_TO_USER, user_id);
            } catch (e) {
                console.log("error", e);
            }
        },
        get_fav_list() {
            callApi("get_fav_list", {}, `get_fav_list`).then((res) => {
                if (res.status < 0) {
                    console.log("get_fav_list error", res.msg)
                    return;
                }
                this.fav_list = res.data;
            })
        },
        remove_fav(item) {
            callApi("remove_fav", { target_id: item.target_id, target_type: item.target_type }, "remove_fav").then(
                (res) => {
                    if (res.status < 0) {
                        console.log("remove_fav error", res.msg)
                        return;
                    }
                    this.get_fav_list();
                }
            )
        }

    }
}
</script>