import Vue from 'vue'
import VueRouter from 'vue-router';
import router from './router'
import { ChatWebSocket } from './js/chat-socket';

Vue.use(VueRouter)

Vue.config.productionTip = false

// 全局唯一
const chatSocket = new ChatWebSocket();
Vue.prototype.$chatSocket = chatSocket;

new Vue({
  router,
}).$mount('#app')
